<template>
  <v-list-item-avatar
      size="32"
      color="#dddddd"
      class="record-list-item-avatar-img">
    <v-img
        v-if="record.adminThumb"
        cover
        :src="record.adminThumb"/>
  </v-list-item-avatar>
</template>

<script>
import RecordMixin from "../RecordMixin";
export default {
  name: "record-list-item-avatar-img",
  components:{},
  mixins:[RecordMixin]
}
</script>
