<template>
<div>

  <table-header
      @search="search=$event"
      @selectColumns="colonnes=$event"
      :selected-columns="colonnes"
      :possible-columns="
            [
              {
                text:'Description',
                value:'description',
              },
              {
                text:'Priorité',
                value:'priority'
              },
              {
                text:'Fréquence',
                value:'changefreq'
              },
              {
                text:'URL',
                value:'slug'
              },
              {
                text:'Canonical',
                value:'canonical'
              },
              {
                text:'JSON',
                value:'jsonld'
              },
              {
                text:'Erreurs',
                value:'errors-details'
              }

              ]"
  />
  <v-data-table
      @click:row="clickRow"
      sort-by="slug"
      class="table-seo"
      :search="search"
      :disable-pagination="false"
      :hide-default-footer="false"
      :headers="headers"
      mobile-breakpoint="md"
      :items="lines">

    <template v-slot:item.title="{ item }">
      <div style="width: 350px" class="py-5">
        <div>{{item.title}}</div>
        <record-list-item :record="item.recordPage"/>
      </div>
    </template>



    <template v-slot:item.slug="{ item }">
      <code>{{item.slug}}</code>
    </template>

    <template v-slot:item.description="{ item }">
      <div style="max-width: 200px" class="py-5">
        {{item.description}}
      </div>
    </template>
    <template v-slot:item.jsonld="{ item }">
      <v-icon v-if="item.jsonld" color="primary">{{$icons.check}}</v-icon>
    </template>
    <template v-slot:item.errors-details="{ item }">
      <div style="max-width: 200px" class="py-5" v-if="item.errors.length">
        <v-alert color="error" class="mb-0" dense dark>
          <div v-for="(err,i) of item.errors" :key="i" class="caption">
            {{err}}
          </div>
        </v-alert>
      </div>
    </template>

    <template v-slot:item.errors="{ item }">
      <table-fields-errors-actions
          :errors="item.erros"
          :record="item.recordPage"
      />
    </template>


  </v-data-table>

</div>
</template>

<script>
import RecordListItem from "@/ee09/db-ui/records/record-list-item";
import TableHeader from "@/ee09/db-ui/table-fields/table-header";
import TableFieldsErrorsActions from "@/ee09/db-ui/table-fields/table-fields-errors-actions";
export default {
  name: "seo-dashboard",
  components: {TableFieldsErrorsActions, TableHeader, RecordListItem},
  data(){
    return {
      //les colonnes affichées par défaut
      colonnes:['priority','slug'],
      search:'',
    }
  },

  computed:{
    headers(){
      let h=[
        {
          text: 'Title',
          align: 'start',
          sortable: true,
          value: 'title',
        }
      ];
      if(this.colonnes.indexOf('description')>-1){
        h.push(
            { text: 'Description', value: 'description' }
        );
      }
      if(this.colonnes.indexOf('priority')>-1) {
        h.push(
            {text: 'Prio', value: 'priority'}
        );
      }
      if(this.colonnes.indexOf('changefreq')>-1) {
        h.push(
          { text: 'Freq', value: 'changefreq' }
        );
      }
      if(this.colonnes.indexOf('slug')>-1) {
        h.push(
            {text: 'Url', value: 'slug'}
        );
      }
      if(this.colonnes.indexOf('canonical')>-1) {
        h.push(
            {text: 'Canonical', value: 'canonical'}
        );
      }
      if(this.colonnes.indexOf('jsonld')>-1) {
        h.push(
            {text: 'JSON LD', value: 'jsonld', sortable: true}
        );
      }
      if(this.colonnes.indexOf('errors-details')>-1) {
        h.push(
            {text: 'Erreurs', value: 'errors-details', sortable: false}
        );
      }
      h.push(
          {
            align: "end",
            text: '',
            value: 'errors',
            sort: (a, b) => a.length - b.length
          }
      );


      return h;
    },
    /**
     *
     * @return {pageUrl[]}
     */
    lines(){
      let list=[];
      for(let rec of this.$db.recordListPage){
        list.push(rec.pageUrl);
      }
      return list;
    }
  },
  methods:{
    clickRow(item){
      window.$dbUi.singleRecord=item.recordPage;
    }
  }
}
</script>

<style lang="less">
.table-seo{
  tr{
    th{
      white-space: nowrap;
    }
    td{
      font-size: 11px !important;
    }
  }
}
</style>