import PageModel from "@/Models/PageModel";
import RecordsField from "@/ee09/fields/RecordsField";

export default class PageWithRelatedsModel extends PageModel{
    constructor() {
        super();
        /**
         *
         * @type {RecordsField}
         */
        this.relateds=new RecordsField();
    }

    get errorsMeta(){
        let r=super.errorsMeta;
        /*
        if(this.relateds.records.length<1){
            r.push("Pas de formation associée")
        }
        */
        return r;
    }

    /**
     * Renvoie true si la page à au moins un des related spécifiés
     * @param {DbRecord[]} records
     */
    hasRelateds(records){
        for(let related of this.relateds.records){
            for(let record of records){
                if(related.uid === record.uid){
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Renvoie la liste des pages d'un type donné qui partagent les mêmes relations
     * @param {string} type
     * @return {PageWithRelatedsModel[]}
     */
    getRelated(type){
        /**
         *
         * @type {PageWithRelatedsModel[]}
         */
        let relateds=[];
        relateds=this.allRelateds.filter(p=>
            p.type===type
        );
        return relateds;
    }

    get allRelateds(){

        let result=[];
        /**
         *
         * @type {DbRecord}
         */
        let rel=null;
        /**
         *
         * @type {PageWithRelatedsModel}
         */
        let dbRec=null;
        for(rel of this.relateds.records){
            for(dbRec of window.$db.records){
                if(dbRec.uid!== this.uid){
                    if(dbRec.relateds){
                        if(dbRec.relateds.records.find(item=>item.uid===rel.uid)){
                            result.push(dbRec)
                        }
                    }
                }
            }
        }
        return result;
    }

    /**
     * Les articles avec des related en commun
     * @return {PostModel[]|null}
     */
    get relatedPosts(){
        return this.getRelated("post");
    }
    /**
     * Les métiers avec des related en commun
     * @return {MetierModel[]|null}
     */
    get relatedMetiers(){
        return this.getRelated("metier");
    }
    /**
     * Les portraits avec des related en commun
     * @return {PortraitModel[]|null}
     */
    get relatedPortraits(){
        return this.getRelated("portrait");
    }
    /**
     * Les projets avec des related en commun
     * @return {ProjetModel[]|null}
     */
    get relatedProjets(){
        return this.getRelated("projet");
    }

    /**
     * Renvoie un projet asocié de manière pseudo aléatoire
     * @return {ProjetModel|*}
     */
    get projet(){
        let p=this.relatedProjets;
        if(p && p.length){
            return window.$db.utils.array.randomItem(p);
        }
        return null;
    }
}