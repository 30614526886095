<template>
  <div class="page pagelist"
       id="fixed"
       :refresh-id="idx"
       :class="{}"
      :style="{
        '--scroll-y':`${$layout.nativeScroll.y}`,
        '--scroll-y-lerp':`${yLerp}`
       }"
  >
    <div  class="box"
          v-if="$layout.pageBg.currentPage">
      <morph-text
          v-if="$layout.pageBg.currentPage.pagetitle !== $layout.pageBg.currentPage.name"
          class="ty-link text-uppercase font-weight-bold"
          :text="$layout.pageBg.currentPage.pagetitle"
          :duration="0.75"
      />
      <morph-text

          class="ty-rich-text d-block"
          style="white-space: pre-line"
          :duration="1.0"
          algorithm="shuffle"
          :text="$layout.pageBg.currentPage.textposter"/>
    </div>


    <!-- occupe la place de la liste pour palier aux absolutes et gère les clicks-->
    <div class="scroll-menu">
      <div class="items" ref="items">
        <div v-for="p of rec.pages.records"
             :key="p.uid+'m2'"
             :title="p.name"
             :uid="p.uid"
             class="scroll-menu-item"
             :class="{draft:p.draft}"
             @click="goPage(p)">
          <h2 class="text-fat outlined">
            <!--{{$db.utils.string.noAccents(p.name)}}-->
            {{p.name}}
          </h2>
        </div>
      </div>
    </div>




    <div class="m" ref="mask" >
      <div class="items" >
        <div v-for="(p,i) of rec.pages.records"
             :key="p.uid+'m2'"
             class="scroll-menu-item"
             :class="{draft:p.draft}"
        >
          <h2 class="text-fat">
            <!--{{$db.utils.string.noAccents(p.name)}}-->
            {{p.name}}
          </h2>
        </div>
      </div>
    </div>

    <div class="m2">
      <div class="items" >
        <div v-for="(p,i) of rec.pages.records"
             :key="p.uid+'m3'"
             class="scroll-menu-item">
          <h2 class="text-fat outlined">
            <!--{{$db.utils.string.noAccents(p.name)}}-->
            {{p.name}}
          </h2>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import RecordMixin from "@/ee09/db-ui/records/RecordMixin";
import MorphText from "@/components/atoms/morph-text";
import {gsap} from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import Ticker from "ee09.utils/Ticker";
import {lerp} from "ee09.utils/Math";
gsap.registerPlugin(ScrollToPlugin);

export default {
  name: "PagelistPage",
  components: {
    MorphText
  },
  mixins:[RecordMixin],
  data(){
    return {
      idx:0,
      scrollTween:null,
      loopInterval:null,
      isScrolling:false,
      userScrolling:false,
      /**
       * @type {PageModel}
       */
      currentPage:null,
      yLerp:0,
      yNearest:0,
      timeOut:null
    }
  },
  mounted() {
    this.$layout.currentList=this.rec.pages.records;
    this.$layout.pageBg.setCurrentPage(
        this.rec.pages.records[0],
        this.$layout.pageBg.SIZE_FULL
    );
  },

  computed:{
    /**
     *
     * @return {PagelistModel}
     */
    rec(){
      return this.recPage;
    },
    y(){
      return this.$layout.nativeScroll.y;
    }
  },
  watch:{
    y(){
      this.onScroll();
    }
  },


  methods:{
    /**
     *
     * @param {PageModel} page
     */
    goPage(page){
      if(page && !page.draft){
        this.$router.push(page.href)
      }
    },
    onScroll(){

      if(this.isScrolling){
        return;
      }

      this.yLerp=this.y;
      gsap.set(this,{
        "yLerp":this.y,
        overwrite: true
      })

      let nearest=this.getNearestElement();
      if(nearest){
        this.$layout.pageBg.setCurrentPage(nearest,"full");
      }

      //désactive le recallage du scroll
      if(1+1===2){
        return;
      }

      if(this.timeOut){
        clearTimeout(this.timeOut);
      }
      this.timeOut=setTimeout(()=>{
        //this.$layout.nativeScroll.scrollTo(this.yNearest,500)

        gsap.to(this,{
          "yLerp":this.yNearest,
          duration:0.7,
          overwrite: true,
          ease:"Power3.easeInOut",
          onComplete:()=>{
            //this.$layout.nativeScroll.scrollTo(this.yNearest,0)
          }
        })
      },400)

    },
    /**
     *
     * @param {HTMLElement} $element
     * @return {Number}
     */
    getItemPositionY($element){
      let paddingTop=parseFloat(
          window.getComputedStyle(this.$refs.items, null).getPropertyValue('padding-top')
      );
      return $element.offsetTop-paddingTop;

    },
    /**
     * Renvoie l'étément html correspondant à cet item
     * @param {PageModel} item
     * @return {HTMLElement}
     */
    getItemElement(item){
      return this.$el.querySelector(`[uid="${item.uid}"]`)
    },
    /**
     *
     * @return {null|PageModel}
     */
    getNearestElement(){
      let me=this;
      let m =this.$refs.mask;
      for (let child of this.$refs.items.children) {
        let maskPositions=m.getBoundingClientRect();
        let childPositions=child.getBoundingClientRect();
        let middle=(childPositions.top+childPositions.bottom)/2
        if(middle > maskPositions.top && middle < maskPositions.bottom){
          this.yNearest=this.getItemPositionY(child) ;
          return me.$db.getByUid(child.getAttribute('uid'));
        }
      }
      return null;
    },
  }
}
</script>

<style lang="less">

.pagelist{
  //overflow-y: hidden;
  --item-h:11vw;
  --scroll-y:0;
  --scroll-y-lerp:0;

  user-select: none;

  //-----fat typo------------

  .text-fat{
    color: var(--color-project);
    &.outlined{
      color: transparent;
      -webkit-text-stroke-color: var(--color-project);
      -webkit-text-stroke-width:0.3px;
      @media(min-width: @screen-sm){
        -webkit-text-stroke-width:1.5px;
      }
    }
  }

  .items{
    padding-top: calc( 35vh - var(--item-h) / 2 );
    padding-bottom: calc( 65vh - var(--item-h) / 2 );
    @media(min-width: @screen-md){
      padding-top: calc( 50vh - var(--item-h) / 2 );
      padding-bottom: calc( 50vh - var(--item-h) / 2 );
      max-width: 65vw;
    }
    padding-left: 20px;

  }

  .scroll-menu{

    .items{
      .text-fat{
        color: #ff0000;
        opacity: 0.3;
        opacity: 0;
      }
    }
  }

  .m,.m2{ //masque
    pointer-events: none;
    position: absolute;
    left: 0px;
    height: var(--item-h);
    width: 100%;
    //top: calc( 35vh - var(--item-h) / 2 );
    top: calc( var(--scroll-y)        *  1px + 35vh - var(--item-h) / 2);
    @media(min-width: @screen-md){
      top: calc( var(--scroll-y)        *  1px + 50vh - var(--item-h) / 2);
    }
    .items{
      position: absolute;
      //top: calc( var(--scroll-y) * -1px  - 35vh + var(--item-h) / 2);
      top: calc( var(--scroll-y-lerp) * -1px - 35vh + var(--item-h) / 2);
      @media(min-width: @screen-md){
       top: calc( var(--scroll-y-lerp) * -1px - 50vh + var(--item-h) / 2);
      }
    }



  }
  .m{
    overflow: hidden;
    opacity: 0.5;
    opacity: 1;
    //outline: 1px solid red;
    outline-offset: -2px;
  }
  .m2{
    overflow: visible;
    //outline: 1px solid green;
    outline-offset: -4px;
  }

  .box{
    position: fixed;
    top: 80vh;
    left: 20px;
    width: calc(100vw - 40px);;
    @media(min-width: @screen-md){
      left: 65vw;
      bottom: auto;
      top:  calc(50vh + var(--item-h) * 0.25 );
      width: calc(35vw - 20px);
      max-width: 400px;
    }
    line-height: 1.5;
    h3{
      color: var(--color-project);
      text-transform: uppercase;
    }
  }

  .scroll-menu-item{
    //outline: 1px solid blue;
    padding-top: 1.2vw;
    padding-bottom: 1.2vw;
    cursor: pointer;
    //height: var(--item-h);
    display: flex;
    align-items: center;
    @media(min-width: @screen-lg){
      padding-left: 100px;
    }

    &.draft{
      pointer-events: none;
      opacity: 0.2;
    }

  }


}



</style>