<template>
  <div class="blocks" v-if="fld && fld.blockList">

    <add-block-buttons
        v-if="$dbUi.preferences.wysiwygEnabled && !$dbUi.pageHtmlToGenerate
        && blocksField.blockList.length===0"
        :field="blocksField"
        class="my-10" insert-top/>

    <template v-for="block in blocksField.blockList">
      <wysiwyg-block-wrapper
          :block="block"
          :field="blocksField"
          :key="block.uid">
        <component v-if="block.blockModel"
                   :is="block.blockModel.displayComponent"
                   :block="block"
                   class="block"
        />
      </wysiwyg-block-wrapper>
    </template>

  </div>
</template>

<script>

import WysiwygBlockWrapper from "@/Models/blocks/wysiwyg-block-wrapper";
import BlocksField from "@/ee09/fields/BlocksField";
import AddBlockButtons from "@/ee09/db-ui/fields/blocks/add-block-buttons";
export default {
  name: "blocks",
  components: {AddBlockButtons, WysiwygBlockWrapper},
  props:{
    blocksField:{
      type:BlocksField,
      mandatory:true,
      validate(){
        return true;
      }
    }
  },
  computed:{
    /**
     *
     * @return {BlocksField}
     */
    fld(){
      return this.blocksField;
    }
  }
}
</script>

<style lang="less">
  .blocks{
    padding-bottom: 20px;
    >*{
    >.block{
        padding-top:    calc( var(--line-unit) * 0.5 );
        padding-bottom: calc( var(--line-unit) * 0.5 );
      }
    }
  }
</style>