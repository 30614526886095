<template>
  <div class="page page-page pagefilm-page">
    <div class="poster-placeholder"
         :data-poster-size="$layout.pageBg.size"/>
    <v-container class="over-poster py-0">
      <h1 class="ty-title-page"
          :class="{
            'is-long-title':film.isLongTitle
          }"
          style="white-space: pre-line;">
        {{rec.pagetitle?rec.pagetitle:rec.name}}
      </h1>

    </v-container>
    <section v-if="film.textposter">
      <v-container>
        <h2 class="un-film-de">{{film.textposter}}</h2>
      </v-container>
    </section>
    <section >
      <v-container class="film-metas">

        <v-row>
          <v-col cols="12" sm="6" class="ty-rich-text col-txt">
            <h3 class="mb-5 mb-sm-0">{{film.unfilmde}}</h3>
            <div class="ty-link casting">
              <pre style="white-space: pre-line;">{{film.casting}}</pre>

            </div>
          </v-col>

          <v-col cols="12" sm="6" class="col-img">
            <img alt=""
                :src="film.affiche.resize(1600,1600,'contain',
            '000000',80,'webp'
            )">
          </v-col>
        </v-row>
      </v-container>
    </section>



    <section>
      <blocks :blocks-field="recPage.blocks" />
      <v-container>
        <prev-next
            :prev="$layout.prevPage"
            :next="$layout.nextPage"
            class="my-10"
        />
      </v-container>
    </section>

    <marquee-text
        class="txt-scroll ty-title-paragraph"
        :repeat="10" :duration="10">
      [ {{this.recPage.name}} ] &nbsp;
    </marquee-text>

  </div>

</template>

<script>
import RecordMixin from "@/ee09/db-ui/records/RecordMixin";
import Blocks from "@/Models/blocks/blocks";
import PrevNext from "@/pic/prev-next";
import MarqueeText from 'vue-marquee-text-component'

export default {
  name: "PagefilmPage",
  components: {
    PrevNext,
    Blocks,
    MarqueeText
  },
  mixins:[RecordMixin],
  mounted() {
    this.$layout.pageBg.setCurrentPage(this.recPage,this.$layout.pageBg.SIZE_BANDEAU);
    this.$layout.currentList=this.$layout.filmsList;
  },
  computed:{
    /**
     *
     * @return {PagefilmModel}
     */
    film(){
      return this.recPage;
    }
  }
}
</script>

<style lang="less">
.pagefilm-page{

  .un-film-de{
    font-family: var(--font-title);
    font-weight: 600;
  }

  .film-metas{
    padding-top: var(--s0);
    padding-bottom: var(--s0);
    .col-img,.col-txt{
      outline-offset: -2px;
    }

    .col-txt{
      display: flex;
      flex-direction: column;
      .casting{
        flex-grow: 1;
        display: flex;
        align-items: center;
        >pre{
          font-family: var(--font-title);
          max-width: 100%;
          font-weight: 600;
        }
      }
    }
    .col-img{
      display: flex;
      img{
        object-fit: contain;
        max-width: 100%;
        //height: 70vh;
      }
    }
  }
}


</style>