var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"side-menu"},[_c('div',{staticClass:"items"},[_vm._l((_vm.items),function(p,idx){return _c('div',{key:p.uid,staticClass:"item",attrs:{"title":p.name,"uid":p.uid}},[_c('h2',{staticClass:"main-item ty-title-paragraph ",class:{
           'active':p.uid===_vm.$layout.currentUid,
           'has-href-underline':p.uid!==_vm.$layout.currentUid
         }},[_c('router-link',{attrs:{"to":p.href}},[_c('morph-text',{ref:"items",refInFor:true,attrs:{"text":p.name,"duration":0.25,"delay":0.25+idx * 0.15,"algorithm":"ltr type"}})],1)],1),_c('div',{staticClass:"sub-items has-href-underline",class:{
              'open':p.uid===_vm.$layout.currentUid && _vm.ancres.length
           }},_vm._l((_vm.ancres),function(ancre,idxa){return _c('h3',{key:ancre.href,staticClass:"sub-item ",class:{
              'active':ancre.uid===_vm.$layout.currentAnchor
            }},[_c('router-link',{staticClass:"ty-rich-text",attrs:{"to":ancre.href}},[_c('morph-text',{ref:"ancres",refInFor:true,attrs:{"text":ancre.label,"duration":0.5,"delay":0.25+ idxa * 0.15,"algorithm":"ltr type"}}),_vm._v("  ")],1)],1)}),0)])}),_c('div',{staticClass:"autre-site"},[_c('p',{staticClass:"ty-rich-text mb-0"},[_vm._v(" "+_vm._s(_vm.$layout.footer.getLinkItem(_vm.$layout.isFilms?'site-prod':'site-films').label))]),_c('a',{attrs:{"href":_vm.$layout.footer.getLinkItem(_vm.$layout.isFilms?'site-prod':'site-films').href,"target":"_blank"}},[_c('img',{staticStyle:{"max-width":"175px"},attrs:{"src":((_vm.$layout.publicPath) + "logo-" + (_vm.$layout.isFilms?'prod':'films') + "-v2.svg"),"alt":"logo Picseyes"}})])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }