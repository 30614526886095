export default class Stats{
    constructor(GAid) {
        this.GAid=GAid;
        this._disabled=localStorage.getItem("ga-disabled");
    }
    set disabled(value){
        let olddisabled=this._disabled;
        if(value==="yes"){
            window['ga-disable-'+this.GAid] = true;
        }else{
            window['ga-disable-'+this.GAid] = false;
            this._disabled=false;
        }
        this._disabled=value;
        localStorage.setItem("ga-disabled",value);
        if(olddisabled==="yes" && value==="no"){
            document.location.reload();
        }
    }
    get disabled(){
        return this._disabled==="yes";
    }
    pageView(path,title){
        if(this.disabled){
            console.warn("GA disabled by user");
            return
        }
        //console.log("pageview",path,title);
        if(!this.GAid){
            console.warn("no GAid");
            return
        }
        if(!window.gtag){
            console.warn("no gtag");
            return
        }
        window.gtag('event', 'page_view', {
            page_path: path,
            page_title : title,
        });
    }
}