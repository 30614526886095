<template>
<div class="image-zoom animate__animated animate__fadeIn"
     @wheel.prevent
     @touchmove.prevent
     @scroll.prevent
     tabindex="0"
     @keyup.e.esc="$layout.imagesZoom.open=false"
     @keyup.left="prev()"
     @keyup.right="next()"
>
  <img
      ref="img"
      :src="imgSrc"
  />
  <nav>

    <button
        class="close ty-title-link"
        @click="$layout.imagesZoom.open=false;">
      ✗
    </button>
    <template v-if="$layout.imagesZoom.images.length>1">
    <button
        class="prev ty-title-link"
        @click="prev();">
      <-
    </button>
    <button
        class="next ty-title-link"

        @click="next();">
      ->
    </button>
    </template>
  </nav>



</div>
</template>

<script>
import {gsap} from "gsap";
export default {
  name: "image-zoom",
  data(){
    return{
      im:this.$layout.imagesZoom.currentImage
    }
  },
  mounted() {
    this.$el.focus()
  },
  computed:{
    moveFactor(){
      return screen.width*0.7;
    },
    img(){
      return this.$layout.imagesZoom.currentImage
    },
    imgSrc(){
      return this.img.resize(
          1600,1600,'contain',
          'transparent',
          80,'webp')
    }
  },
  methods:{
    prev(){
      const m=this.moveFactor;
      let tl=gsap.timeline();
      tl.to(this.$refs.img,{
            x:m,
            opacity:0,
            duration:0.4
          }
      );
      tl.call(()=>{
        this.$layout.imagesZoom.prev()
      })
      tl.set(this.$refs.img,{x:-m,opacity:0},"+=0.1");
      tl.to(this.$refs.img,{
        x:0,opacity:1,
        duration:0.4
      });
    },
    next(){
      const m=this.moveFactor;
      let tl=gsap.timeline();
      tl.to(this.$refs.img,{
            x:-m,
            opacity:0,
            duration:0.4
          }
      );
      tl.call(()=>{
        this.$layout.imagesZoom.next()
      })
      tl.set(this.$refs.img,{x:m,opacity:0},"+=0.1");
      tl.to(this.$refs.img,{
        x:0,opacity:1,
        duration:0.4
      });
    }
  }
}
</script>

<style lang="less">
.image-zoom{

  button{
    //outline: 1px solid pink;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 2em;
    white-space: nowrap;
    outline: none !important;
    transition: transform 0.2s;
    &:hover{
      transform: scale(1.1);
    }
  }

  --pad:1vw;

  z-index: var(--z-popin);
  position: fixed;
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: saturate(0) blur(4px);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  img{
    position: absolute;
    width: calc(100% - var(--pad) * 2);
    left: var(--pad);
    height: 90%;
    object-position: center;
    object-fit: contain;
  }

  nav{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    >*{
      position: absolute;
    }
    .close{
      top: var(--pad);
      right: var(--pad);
    }

    .prev,.next{
      bottom: var(--pad);
    }
    .prev{
      right: calc(50% + var(--pad));
    }
    .next{
      left: calc(50% + var(--pad));
    }
  }

  @media(min-width: @screen-md){

    img{
      width: 90%;
      height: 90%;
      left: 5%;
      top: 5%;
    }

    nav{
      .prev,.next{
        top: 50%;
        bottom: auto;
      }
      .prev{
        left:var(--pad);
        right: auto;
      }
      .next{
        right: var(--pad);
        left: auto;
      }
    }

  }


}
</style>