import LinkItem from "@/ee09/models/LinkItem";
import BlockItem from "@/ee09/fields/BlockItem";

export default class CoreLinkItemBlock extends BlockItem{
    constructor() {
        super();
        this.blockType="core-link-item-block";
        /**
         * Le lien
         * @type {LinkItem}
         */
        this.link=new LinkItem();
    }

}