<template>
<span
    class="morph-text"
    :key="idx"
    v-html="text2"></span>
</template>

<script>
import MorphText from "ee09.utils/MorphText/MorphText";

/**
 * Composant qui permet d'afficher du texte morphé
 */
export default {
  name: "morph-text",
  data:()=>{
    return{
      idx:0,
      text2:"",
      /**
       * @type {MorphText}
       */
      morph:null,
      /**
       *
       */
      delayTimeout:0

    }
  },
  props:{
    text:{
      type:String,
      default:""
    },
    duration:{
      type:Number,
      default: 1
    },
    algorithm:{
      type:String,
      default: "ltr"
    },
    delay:{
      type:Number,
      default: 0
    }
  },
  computed:{
    /**
     * @type {MorphTextOption}
     */
    options(){
      return {
        duration: this.duration,
        algorithm: this.algorithm,
        fps:100
      }
    }
  },
  watch:{
    text(){
      if(this.morph !==null){
        this.morph.stop();
        this.morph.to(
            this.text2,
            this.text,
            (t)=>{
              this.$emit("changeText",t)
              this.text2=t
            },
            ()=>{},
            this.options
        )
      }
    }
  },
  mounted() {
    this.morph=new MorphText()
    this.shake();
  },
  methods:{
    /**
     * Remet le texte à zéro et relance l'animation
     */
    shake(){
      //console.log("shake",performance.now())
      this.idx++;
      this.text2="";
      if(this.morph !== null) {
        this.morph.stop();
      }
      if(this.delayTimeout){
        clearTimeout(this.delayTimeout);
      }
      this.delayTimeout=setTimeout(()=>{

        if(this.morph !== null){
          //console.log("shake go",this.text,performance.now())
          this.morph.stop();
          this.morph.to(
              "",
              this.text,
              (t)=>{
                this.$emit("changeText",t)
                this.text2=t
              },
              ()=>{},
              this.options
          );
        }
      },this.delay*1000);
    }
  }
}
</script>

<style scoped>

</style>