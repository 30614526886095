import BlockItem from "@/ee09/fields/BlockItem";
import ItemList from "@/ee09/models/ItemList";
import ImageItem from "@/ee09/models/ImageItem";
import ImageField from "@/ee09/fields/ImageField";

export default class DiaporamaBlock extends BlockItem{
    constructor() {
        super();
        this.blockType="diaporama-block";
        /**
         *
         * @type {ImageItemList}
         */
        this.photos=new ItemList(
            function(imageField){
                return new ImageItem(imageField);
            }
        );
    }

    mount(data) {
        super.mount(data);
        this.photos._processData();
        for(let i of this.photos.items){
            if(!i.image){
                i.image=new ImageField();
            }
        }
    }
}