const merge=require('lodash/merge');
export default class BlockItem{
    constructor() {
        /**
         * L'identifiant unique de ce block
         * @type {string}
         */
        this.uid=window.$db.utils.string.uniqId("block-","-"+Math.round(Math.random()*9999999));
        /**
         * Le type de block
         * @type {string}
         */
        this.blockType="";
        /**
         * Le nom de ce block qui fait office de nom de variable
         * @type {string}
         */
        this.name="";

    }
    /**
     * Si on peut afficher le block
     * @return {boolean}
     */
    get isOk(){
        return true;
    }
    get hello(){
        return "Hello from BlockItem";
    }

    /**
     * Ce getter permet de s' assurer que les données affichées soient bien celles issues d' un block JAVASCRIPT et non simplement d' un json
     * @return {boolean}
     */
    get isInit(){
        return true;
    }
    /**
     * Charge les données dans l'objet
     * @param {*} data Les données issues du json
     */
    mount(data){
        if(data){
            merge(this,data);
        }
    }

    /**
     * Renvoie le modèle du type de block
     * @return {BlockType}
     */
    get blockModel(){
        return window.$db.blocksSettings.getBlockModelType(this.blockType);
    }

    /**
     * Renvoie les images qui son utilisées dans ce block
     * @return {ImageField[]}
     */
    get imagesFromContent(){
        let r=[];
        return r;
    }
}