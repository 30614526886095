import BlockItem from "@/ee09/fields/BlockItem";
import RecordsField from "@/ee09/fields/RecordsField";

export default class FilmsBlock extends BlockItem{

    constructor() {
        super();
        this.blockType="films-block";

        /**
         * La listes des films
         * @type {RecordsField}
         */
        this.records=new RecordsField();

        /**
         * Si true alors on affiche deux colonnes
         * @type {boolean}
         */
        this.dense=true;
    }

    /**
     * La liste des films
     * @return {PagefilmModel[]}
     */
    get films(){
        /**
         *
         * @type {PagefilmModel[]}
         */
        let r= this.records.records;
        //les pages non draft
        r=window.$db.utils.records.draftsIfAdmin(r);
        return r;
    }

    get isOk(){
        return window.$db.userAdmin || this.films.length>0
    }

}