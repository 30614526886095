import Vue from 'vue'

//models-------------

import PagePage from "./page-page";
import PagelistPage from "./pagelist-page";
import PostPage from "./post-page";
import PagefilmPage from "./pagefilm-page";
import PagelocationPage from "@/Models/pagelocation-page.vue";

Vue.component('page-page',PagePage);
Vue.component('post-page',PostPage);
Vue.component('pagelist-page',PagelistPage);
Vue.component('pagefilm-page',PagefilmPage);
Vue.component('pagelocation-page',PagelocationPage);


let modelsEdit=[
    "./opendata",
    "./user",
    "./page",
    "./pagefilm",
    "./pagelocation",
    "./pagelist",
    "./post",
    "./filerecord",
]
for(let path of modelsEdit){
    let split=path.split("/");
    let name=split[split.length-1];
    Vue.component(
        name+"-edit",
        () => import(
            /* webpackMode: "lazy-once", webpackChunkName: "edit"  */
        path+'-edit.vue'
            )
    )
}

// core blocks --------------------------------

let coreBlocksEdit=[
    "./core-blocks/named-block-layout",
    "./core-blocks/core-string-block-edit",
    "./core-blocks/core-string-html-block-edit",
    "./core-blocks/core-link-item-block-edit",
    "./core-blocks/core-link-item-list-block-edit"
];

for(let path of coreBlocksEdit){
    let split=path.split("/");
    let name=split[split.length-1];
    Vue.component(
        name,
        () => import(
            /* webpackMode: "lazy-once", webpackChunkName: "edit"  */
            path+'.vue'
        )
    )
}

let blocks=[
    "./blocks/texte/paragraph-block",
    "./blocks/media/iframe-block",
    "./blocks/media/media-text-block",
    "./blocks/media/diaporama-block",
    "./blocks/media/accordion-block",
    "./blocks/media/accordion-location-block",
    "./blocks/media/photo-list/photo-list-block",
    "./blocks/links/btn-block",
    "./blocks/links/films-block",
    "./blocks/links/locations-block",
    "./blocks/links/icones-liens-block",
]
for(let b of blocks){
    let split=b.split("/");
    let name=split[split.length-1];
    Vue.component(
        name+'-edit',
        () => import(
            /* webpackMode: "lazy-once", webpackChunkName: "edit"  */
            b+'-edit.vue'
        )
    )
    Vue.component(
        name+'',
        () => import(
            /* webpackMode: "lazy-once", webpackChunkName: "blocks"  */
        b+'.vue'
    )
    )
}
