<template>
<div class="pic-page-bg"
     v-if="!$layout.isHtmlCaching"
     :data-poster-size="$layout.pageBg.size">
  <div class="pages" ref="list">
    <template v-for="(p) of pages">
        <page-poster
            :ref="p.uid"
            :key="p.uid"
            :record="p"
            :class="{
                'visible':current && current.uid === p.uid
            }"
        />
    </template>


  </div>

</div>
</template>

<script>
import PagePoster from "@/pic/page-poster";
export default {
  name: "pic-page-bg",
  components: {PagePoster},
  computed:{
    /**
     *
     * @return {PageModel}
     */
    current(){
      return this.$layout.pageBg.currentPage
    },
    /**
     *
     * @return {PageModel[]}
     */
    pages(){
      let liste= this.$layout.defaultList;
      if(this.current){
        liste.push(this.current);
      }
      return this.$db.utils.records.unique(liste);
    }
  },
  created() {
    setTimeout(()=>{
      this.playCurrent()
    },1000)

  },
  watch:{
    current(){
      setTimeout(()=>{
        this.playCurrent();
      },100)

    }
  },
  methods:{
    pauseAll(){
      this.$el.querySelectorAll(".page-poster").forEach(($v)=>{
        //$v.classList.remove("visible");
        $v.querySelector('video').pause();
      })
    },
    playCurrent(){
      this.pauseAll();
      let $container;
      $container=this.$refs[this.current.uid];
      if($container){
        if(Array.isArray($container)){
          $container=$container[0];
        }
        $container=$container.$el;
        //$container.classList.add("visible");
        const $vdo=$container.querySelector("video")
        if($vdo){
          $vdo.play().catch(err=>{
            console.log("err",err)
          });
        }
      }
    }
  }
}
</script>

<style lang="less">
.pic-page-bg{
  overflow: hidden;



  .page-poster{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    &.visible{
      display: block;
    }
  }

  //debugg
  /*
  .pages{
    display: flex;
    flex-wrap: wrap;
    .page-poster{
      border: 1px solid red;
      width: 19vw;
      height: 10vw;
      margin: 0.1vw;
      &.visible{
        border: 1px solid greenyellow;
      }
    }
    .video-not-in-list{
      border-style: dashed !important;
    }
  }

   */






}
</style>