import DbRecord from "@/ee09/records/DbRecord";
import isEmail from "validator/lib/isEmail";
const sha512 = require('hash.js/lib/hash/sha/512');

export default class UserModel extends DbRecord{
    constructor() {
        super();
        this.type="user";
        this.email="";
        this.hashedpwd="";
        this.isadmin=false;
        this.isdev=false;
        //initialise des getter/setter
        this.canManageForms=false;
        this.canEditContent=false;
        this.canEditLayout=false;
        this.canEditRedirections=false;
        this.canManageUsers=false;
    }

    mount(data){
        super.mount(data);
        if(this.email){
            this.name=this.email
        }
    }

    get clearPwd(){
        return this._clearPwd;
    }
    set clearPwd(value){
        this.hashedpwd=UserModel.hashPwd(value);
    }

    /**
     * Renvoie un pwd sha 512 hashé
     * @param {string} pwd
     * @return {string}
     */
    static hashPwd(pwd){
        return sha512().update(pwd).digest('hex');
    }
    static passwordRules(){
        return [
            v => !!v || 'Saisissez votre mot de passe',
            v => (v && v.length >= 8) || 'Ce mot de passe est trop court',
        ]
    }
    static emailRules(){
        return [
            function(v){
                if(!v){
                    return 'Veuillez saisir votre email'
                }
                return true;
            },
            function(v){
                if(v && !isEmail(v)){
                    return "Cet email n'est pas correct"
                }
                return true;
            },
        ]
    }
    set canManageForms(value){
        this.extravars.canManageForms=value;
    }
    get canManageForms(){
        return this.extravars.canManageForms
    }
    set canEditContent(value){
        this.extravars.canEditContent=value;
        if(!this.canEditContent){
            this.canEditLayout=false;
            this.canEditRedirections=false;
            this.canManageUsers=false;
        }
    }
    set canEditLayout(value){
        this.extravars.canEditLayout=value;
    }
    set canManageUsers(value){
        this.extravars.canManageUsers=value;
    }
    set canEditRedirections(value){
        this.extravars.canEditRedirections=value;
    }
    get canEditContent(){
        return this.extravars.canEditContent
            || this.isdev
    }
    get canManageUsers(){
        return (this.canEditContent && this.extravars.canManageUsers)
        || this.isdev
    }
    get canEditLayout(){
        return this.canEditContent && this.extravars.canEditLayout
    }
    get canEditRedirections(){
        return this.canEditContent && this.extravars.canEditRedirections
    }



    /**
     * Est-ce l'utilisateur connecté?
     * @returns {null|boolean}
     */
    get isTheCurrentUser(){
        return window.$db.user && (this.uid === window.$db.user.uid);
    }


}