<template>
<div v-if="dateField">
  <div class="caption text-no-wrap black--text">{{dateField.humanDistance}}</div>
  <div class="caption text-no-wrap text--secondary">{{dateField.humanDisplay}}</div>
</div>
</template>

<script>
import DateField from "@/ee09/fields/DateField";

export default {
  name: "table-field-date",
  props:{
    dateField:{
      type:DateField,
      validate:function(){
        return true
      }
    }
  }
}
</script>

