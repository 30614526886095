<template>
  <v-list-item-content class="record-list-item-content">
    <v-list-item-title class="text-truncate" :title="record.name">
      <span class="is-small">{{nameTruncate}}</span>
      <span class="is-big">{{record.name}}</span>
    </v-list-item-title>
    <v-list-item-action-text>
      <v-icon small v-if="record.adminSubtitleIcon">
        {{record.adminSubtitleIcon}}
      </v-icon>
      {{record.adminSubtitle}}
    </v-list-item-action-text>
    <v-list-item-action-text>
      <slot name="more"></slot>
    </v-list-item-action-text>
  </v-list-item-content>
</template>

<script>
import RecordMixin from "../RecordMixin";

export default {
name: "record-list-item-content",
  mixins:[RecordMixin],
  props:{},
  computed:{
    isSmall(){
      if(this.$el){
        return this.$el.clientWidth<400;
      }else{
        return true;
      }

    },
    nameTruncate(){
      if (this.record.name.length > 30) {
        return this.record.name.substring(0, 30) + '...';
      }
      return this.record.name;
    }
  }

}
</script>
<style lang="less">
.record-list-item-content{
  .is-big{
    display: var(--display-is-big,none);
  }
  .is-small{
    display: var(--display-is-small,inline);
  }
}


</style>