import BlockItem from "@/ee09/fields/BlockItem";
import RecordsField from "@/ee09/fields/RecordsField";

export default class LocationsBlock extends BlockItem{

    constructor() {
        super();
        this.blockType="locations-block";

        /**
         * La listes des location
         * @type {RecordsField}
         */
        this.records=new RecordsField();

        /**
         * Si true alors on affiche deux colonnes
         * @type {boolean}
         */
        this.dense=true;
    }

    /**
     * La liste des locations
     * @return {PagelocationModel[]}
     */
    get locations(){
        /**
         *
         * @type {PagelocationModel[]}
         */
        let r= this.records.records;
        //les pages non draft
        r=window.$db.utils.records.draftsIfAdmin(r);
        return r;
    }

    get isOk(){
        return window.$db.userAdmin || this.locations.length>0
    }

}