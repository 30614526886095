<template>
<div id="admin-page-html-generator" class="elevation-24">
  <div class="v-application--wrap">
    <component
        v-if="$dbUi.pageHtmlToGenerate"
        :is="$dbUi.pageHtmlToGenerate.pageComponentName"
        :record="$dbUi.pageHtmlToGenerate">
    </component>
  </div>
</div>
</template>

<script>
export default {
  name: "admin-page-html-generator",
  components: {}
}
</script>

<style lang="less">
#admin-page-html-generator{
  display: none;
  position: fixed;
  top: 100px;
  right: 30px;
  transform-origin: top right;
  transform: scale(0.15);
  width: 1200px;
  min-height: 100px;
  background-color:#FFF;
  outline: 1px solid black;
  z-index: 1000;
}
</style>