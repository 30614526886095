export default class PageBg{
    /**
     * Le fond de page (vidéo)
     */
    constructor() {
        this.SIZE_FULL="full";
        this.SIZE_BANDEAU="bandeau";
        this.SIZE_NONE="none";

        this.size=this.SIZE_FULL;

        this.video="";
        /**
         *
         * @type {PageModel}
         */
        this.currentPage=null;

        /**
         * Permet de refresh le module
         * @type {number}
         */
        this.counter=0;



    }

    /**
     * Définit le fond à partir d'une page
     * @param {PageModel} page
     * @param {String} size Hauteur du poster
     */
    setCurrentPage(page,size){
        this.size=size;
        if(page){
            this.currentPage=page;
            if(page.videoposter && page.videoposter.isOk){
                this.video=page.videoposter.href;
            }else{
                this.video=null;
            }
        }
    }
}