export default class ImageZoom{
    constructor() {
        this.open=false;
        /**
         *
         * @type {ImageField[]}
         */
        this.images=[];
        /**
         *
         * @type {ImageField}
         */
        this.currentImage=null;
    }

    next(){
        this.currentImage = window.$utils.array.next(this.currentImage,this.images,true);
    }
    prev(){
        this.currentImage = window.$utils.array.prev(this.currentImage,this.images,true);
    }

    display(image,images){
        console.log("go")
        this.currentImage=image;
        this.images=images;
        this.open=true;
    }


}