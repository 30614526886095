<template>
  <div class="ee09-admin-add-block-buttons" :class="{'open':open}">
    <div v-if="!open" class="text-center">
      <v-btn fab x-small
             dark
             elevation="0"
             @click="open=true"
             class="mx-auto add-btn ee09-admin-fade-in"
      >
        <v-icon small>{{$icons.plus}}</v-icon>
      </v-btn>
    </div>

    <v-card
        v-if="open"
        elevation="20"
        class="mx-auto ee09-admin-fade-in"
        max-width="500"
        tile
        >
      <v-system-bar dark color="#666" height="50px;">
        <span class="">Que souhaitez-vous ajouter ?</span>
        <v-spacer></v-spacer>
        <v-btn small icon @click="open=false">
          <v-icon small>{{$icons.close}}</v-icon>
        </v-btn>


      </v-system-bar>
      <v-list dense>
        <!-- <v-subheader>Que souhaitez-vous ajouter?</v-subheader> -->

        <v-list-item-group>
          <template v-for="(blockModelType,i) of fld.getLibrary()">

            <v-list-item
                v-if="blockModelType.type !== undefined"
                :key="blockModelType.type"
                @click="add(blockModelType)"
            >
              <v-list-item-icon>
                <v-icon v-text="blockModelType.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="blockModelType.label"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-subheader v-else :key="i">
              {{blockModelType.label}}
            </v-subheader>
          </template>

        </v-list-item-group>
      </v-list>

    </v-card>

  </div>
</template>

<script>
export default {
  name: "add-block-buttons",
  data(){
    return {
      open:false
    }
  },
  props:{
    field:{
      type:Object
    },
    isBeforeBlock:{
      type:Object
    },
    isAfterBlock:{
      type:Object
    },
    insertTop:{
      type:Boolean
    },
    insertBottom:{
      type:Boolean
    }
  },
  computed:{
    /**
     *
     * @return {BlocksField}
     */
    fld(){
      return this.field;
    },
    position(){
      switch (true){

        case this.isAfterBlock !== undefined:
          return this.isAfterBlock;

        case this.isBeforeBlock !== undefined:
          return this.isBeforeBlock;

        case this.insertTop:
          return 'start';

        case this.insertBottom:
        default:
          return 'end';
      }
    }
  },
  methods:{
    add(blockModelType){
      this.fld.addBlock(blockModelType,this.position,this.isBeforeBlock!==undefined);
      this.open=false;
    }
  }
}
</script>

<style lang="less">
.ee09-admin-add-block-buttons {
  //outline: 1px solid red;
  height: 10px;
  .add-btn{
    margin-top: -10px;
  }
  &.open{
    height: auto;
  }
}
</style>