<template>
<div @eeecontextmenu="displayContextMenu">
  <v-app v-if="$layout.isReady"
         :class="{
          'is-films':$layout.isFilms
         }"
         :is-home="$layout.isHome"
         :nav-open="$layout.navOpen"
         :wysiwyg="$dbUi.preferences.wysiwygEnabled">
    <!-- background fixe-->
    <pic-page-bg/>
    <nav-overlay/>


    <div class="side-page">
      <div id="scrollStuff" ref="scrollStuff">
        <div v-if="!$dbUi.visible">
          <router-view-transition/>
          <footer v-if="!$layout.isHome"  style="background-color: #000">
            <v-container >
              <footer-contact />
            </v-container>
          </footer>

        </div>
      </div>
    </div>

    <image-zoom v-if="$layout.imagesZoom.open"/>

      <!--main-footer/-->
    <accept-cookies style="position: fixed;width: 100%;z-index: 1000;bottom: 0"/>

    <!-- l' admin qui ne s' affiche que si on lui demande -->
    <admin-dialog v-if="$db.userAdmin"/>
    <admin-floating-buttons v-if="$db.userAdmin"/>
    <admin-edit-record v-if="$db.userAdmin"/>
    <admin-page-html-generator
        v-if="$db.userAdmin && $dbUi.pageHtmlToGenerate"
    />

    <!-- librairie de définitions svg-->
    <svg-lib/>

    <!-- force le pré-chargement de certains modules-->
    <div id="no-prefetch" class="d-none">
      <blocks></blocks>
    </div>

    <!-- affiche si on est en xs, sm, md etc... -->
    <div v-if="$dbUi.preferences.displayGraphicsTools"
         id="debug-screen">
      <span class="d-sm-none">xs</span>
      <span class="d-none d-sm-block d-md-none">sm</span>
      <span class="d-none d-md-block d-lg-none">md</span>
      <span class="d-none d-lg-block d-xl-none">lg</span>
      <span class="d-none d-xl-block">xl</span>
    </div>

  </v-app>

  <!-- ici on est en dehors de #app -->

  <div v-else>
    loading...
  </div>
</div>

</template>
<script>
import Blocks from "@/Models/blocks/blocks";
import AdminDialog from "@/ee09/db-ui/admin-dialog";
import AdminFloatingButtons from "@/ee09/db-ui/admin-floating-buttons";
import SvgLib from "@/layout/svg-lib";
import AdminPageHtmlGenerator from "@/ee09/db-ui/admin-page-html-generator";
import AdminEditRecord from "@/ee09/db-ui/admin-edit-record";
import AcceptCookies from "@/components/molecules/accept-cookies";
import PicPageBg from "@/pic/pic-page-bg";
import NavOverlay from "@/layout/nav-overlay";
import FooterContact from "@/pic/footer-contact";
import ImageZoom from "@/image-zoom/image-zoom";
import RouterViewTransition from "@/transitions/router-view-transition";

export default {
  name: 'App',
  components: {
    RouterViewTransition,
    ImageZoom,
    FooterContact,
    NavOverlay,
    PicPageBg,
    AcceptCookies,
    AdminEditRecord,
    AdminPageHtmlGenerator,
    SvgLib,
    AdminFloatingButtons,
    AdminDialog,   Blocks,},
  mounted() {
     setTimeout(()=>{
       this.pageLoad=false;
       },1000
     );

  },
  watch: {
    '$route' (to) {
      this.$layout.navOpen=false;
      let page;
      switch (to.name){

        //simplement l'uid
        case "uid":
          this.$layout.currentUid=to.params.uid;
          break;

        //slug + uid
        case "pageUrlUid":
          page=this.$db.getPageByPageUrlId(to.params.puid);
          if(page){
            if(page.pageUrl.slug!==to.params.slug){
              //si l'url n'est pas l'url optimisée
              this.$router.push(page.href);
              return;
            }
            this.$layout.currentUid=page.uid;
          }
          break;
        // slug tout court ou 404
        case "customurl":
          page=this.$db.getPageBySlug(to.params.catchAll);
          if(page){
            this.$layout.currentUid=page.uid;
          }
          //sinon 404
          break;
      }
    }
  },
  data: () => ({
    /**
     * Devient false après le chargement de la première page
     */
    pageLoad:true,
    contextMenu:{
      x: 0,
      y: 0,
      visible:false,
    },

  }),
  methods:{
    displayContextMenu(e){
      e.preventDefault()
      this.contextMenu.visible = false
      this.contextMenu.x = e.clientX
      this.contextMenu.y = e.clientY
      this.$nextTick(() => {
        this.contextMenu.visible = true
      })
    }
  },

}

</script>
<style lang="less">
@import "./style-guide/typography.less";
@import "./style-guide/spacing.less";
@import "./style-guide/colors.less";
@import "./style-guide/z-index.less";
@import "./style-guide/gradients.less";
@import "./style-guide/shadow.less";

@import "./components/atoms/has-href-underline.less";
@import "./components/atoms/icons.less";
@import "./components/atoms/mask-invp.less";
@import "icons/iffdec-icons/css/iffdec-codes.css";
@import "icons/iffdec-icons/css/iffdec.css";
@import "~animate.css/animate.min.css";
@import "./pic/data-poster-size.less";

body,html{
  //scroll-behavior: smooth;
}
#app{
  .v-main{
   padding-top: 0 !important;
  }

  //affichage taille d'écran
  #debug-screen{
    position: fixed;
    z-index: 1000;
    font-size: 10px;
    background-color: #0EDEDD;
    padding: 0 5px;
  }
  //si on est en html et que vue n'est pas lancé
  &[html-render="1"]{
    .main-nav{
      transform: none !important;
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }


  .v-application--wrap{
    background-color: #000;
    color: #fff;
  }

  .v-main__wrap{
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
  }

}

#scrollStuff{
  //height: 100vh;
  //width: 100%;
}
.c-scrollbar_thumb{
  background-color: #888;
}



body{
  --nav-padding:10px;
  --side-menu-w:100vw;
  --top-logo-h:50px;
  @media(min-width: @screen-md){
    --nav-padding:20px;
    --top-logo-h:60px;
    --side-menu-w:33vw;
  }
  @media(min-width: @screen-lg){
    --nav-padding:30px;
    --top-logo-h:70px;
    --side-menu-w:600px;
  }
}



.pic-page-bg{
  position: fixed;
  top: 0;
  width: 100%;
}

.pic-page-bg{
  transition: all 0.5s ease-in-out;
}

.side-page{
  position: relative;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

[is-home]{
  .pic-page-bg{
    left: 0;
    width: 100%;
  }

}


</style>
