<template>
<div class="side-menu">

  <div class="items">
    <div v-for="(p,idx) of items" :key="p.uid"
         :title="p.name"
         :uid="p.uid"
         class="item">
      <h2 class="main-item ty-title-paragraph "
          :class="{
           'active':p.uid===$layout.currentUid,
           'has-href-underline':p.uid!==$layout.currentUid
         }"
        >
        <router-link :to="p.href">
        <morph-text
            :text="p.name"
            :duration="0.25"
            :delay="0.25+idx * 0.15"
            algorithm="ltr type"
            ref="items"/>
        </router-link>
      </h2>

      <div class="sub-items has-href-underline"
           :class="{
              'open':p.uid===$layout.currentUid && ancres.length
           }"
           >
        <h3 v-for="(ancre,idxa) of ancres"
            :key="ancre.href"
            class="sub-item "
            :class="{
              'active':ancre.uid===$layout.currentAnchor
            }"
        >
          <router-link class="ty-rich-text" :to="ancre.href">
            <morph-text
                :text="ancre.label"
                :duration="0.5"
                :delay="0.25+ idxa * 0.15"
                algorithm="ltr type"
                ref="ancres"/>&nbsp;
        </router-link>
        </h3>
      </div>



    </div>

    <div class="autre-site">
      <p class="ty-rich-text mb-0">
        {{$layout.footer.getLinkItem($layout.isFilms?'site-prod':'site-films').label}}</p>
      <a :href="$layout.footer.getLinkItem($layout.isFilms?'site-prod':'site-films').href" target="_blank">
        <img
            style="max-width: 175px;"
            :src="`${$layout.publicPath}logo-${$layout.isFilms?'prod':'films'}-v2.svg`"
             alt="logo Picseyes"/>
      </a>
    </div>

  </div>

</div>
</template>

<script>
import MorphText from "@/components/atoms/morph-text";
export default {
  name: "side-menu",
  components: {MorphText},
  computed:{
    isOpen(){
      return this.$layout.navOpen
    },
    items(){
      return this.$layout.menuList
    },
    /**
     *
     * @return {*[]}
     */
    ancres(){
      let r=[];
      if(!this.$layout.currentPage.blocks){
        return r;
      }
      for(let bl of this.$layout.currentPage.blocks.blockList){
        if(bl.isAnchor && (["paragraph-block","accordion-block"].indexOf(bl.blockType)>-1  ) ){
          r.push(
              {
                label:bl.anchorName,
                href:"#"+bl.uid,
                uid:bl.uid
              }
          );
        }
      }
      return r;
    },
  },
  watch:{
    isOpen(){
      if(this.isOpen){
        if(this.$refs.items){
          for(let r of this.$refs.items){
            r.shake();
          }
        }
        if(this.$refs.ancres){
          for(let r of this.$refs.ancres){
            r.shake();
          }
        }

      }
    }
  },
  methods:{}
}
</script>

<style lang="less">
.side-menu{
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px) saturate(0);
  display: block;
  padding-left: var(--s2);
  padding-top: var(--s2);
  padding-bottom: var(--s2);
  @media(min-width: @screen-lg){
    padding-left: 5vw;
    padding-top: 5vw;
    padding-bottom: 5vw;
  }
  overflow: overlay !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--color-project);
    border: 1px solid transparent;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-track {
    width: 3px;
    background: rgba(0, 0, 0, 0);

  }
  scroll-behavior: smooth;



  .items{
    .item{
      .main-item{
        a{}
      }
    }
    .sub-items{
      .sub-item{
        a{}
      }
    }
  }

  .sub-items{
    overflow: hidden;

    padding-left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //outline: 1px solid red;
    max-height: 0;

    transition: all 0.5s ease-out;
    &.open{
      transition: all 0.5s ease-in;
      //outline: 1px solid green;
      max-height: 500px;
    }
  }

  .item{
    .main-item{
      a{
        min-height: 1em;
        transition: color 0.5s;
        color: inherit;
        text-decoration: none;
        &.router-link-active{
          color: var(--color-project);
        }
        &:hover{
          opacity: 0.8;
          &.active{
            opacity: 1;
          }
        }
      }
    }
    .sub-item{
      //padding-bottom: 10px;
      >a{
        line-height: 2.5 !important;
      }
      &:first-child{
        margin-top: 20px;
      }
      &:last-child{
        margin-bottom: 50px;
      }
    }


  }

  .autre-site{
    margin-top: var(--s2);
    @media(min-width: @screen-lg){
      margin-top: 3vw;
    }
    a{
      img{
        filter: saturate(0) brightness(2);
      }
      &:hover{
        img{
          filter: none;
        }
      }
    }
  }

}
</style>