export default class EE09localStorage{
    constructor(name) {
        this.name=name;
        this._localStorageName=`EE09localStorage-${this.name}`;
        this._json=this.json;

    }

    /**
     * Returns the local storage value as object
     * @return {{}}
     */
    get json(){
        let val = localStorage.getItem(this._localStorageName);
        if(val){
            return JSON.parse(val);
        }
        return {};
    }

    /**
     *
     * @param {{}} json La valeur sous forme d'objet
     */
    set json(json){
       this._json=json;
        localStorage.setItem(this._localStorageName,JSON.stringify(this._json));
    }

    /**
     *
     * @return {EE09localStorage}
     */
    save(){
        this.json=this._json;
        return this;
    }

    /**
     * To set and store a variable
     * @param {String} key
     * @param {*} value
     */
    setValue(key,value){
        console.log("setValue("+key+",",value);
        this["_"+key]=value;
        this._json[key]=value;
        return this.save();
    }

    /**
     * To get a variable value
     * @param key
     * @return {*}
     */
    getValue(key){
        return this._json[key];
    }
}