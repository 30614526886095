<template>
<div class="block-wrapper"
     @mouseenter="hover=true"
     @mouseleave="hover=false">
    <slot/>
    <div class="wysiwyg-layer"
         v-if="wysiwygVisible">

      <!-- ajout de block -->
      <v-btn fab small class="top center mt-n5" elevation="15"
             v-if="field"
             @click="insertBlockOpen=true;insertBefore=true;">
        <v-icon small>{{ $icons.plus }}</v-icon>
      </v-btn>
      <v-btn fab small class="bottom center mb-n5" elevation="15"
             v-if="field"
             @click="insertBlockOpen=true;insertBefore=false;">
        <v-icon small>{{ $icons.plus }}</v-icon>
      </v-btn>

      <!-- edit, move, delete block -->
      <v-btn-toggle
          class="top right elevation-15 mr-5 mt-n5"
          rounded
      >
        <v-btn
            v-if="block.blockModel.editComponent"
            @click="focusBlock">
            <v-icon small>{{ $icons.config }}</v-icon>
        </v-btn>
        <v-btn
            v-if="field"
            @click="field.moveUp(block)">
          <v-icon small>{{ $icons.arrowUp }}</v-icon>
        </v-btn>
        <v-btn
            v-if="field"
            @click="field.moveDown(block)">
          <v-icon small>{{ $icons.arrowDown }}</v-icon>
        </v-btn>
        <v-btn
            v-if="field"
            @click="field.removeBlock(block)">
          <v-icon small>{{ $icons.trash }}</v-icon>
        </v-btn>
      </v-btn-toggle>

      <!-- fenêtre edit block -->
      <v-dialog
          v-model="confOpen"
          transition=""
          scrollable
          max-width="800">
        <v-card id="singleBlockDialog">
          <v-card-title class="pa-0">
            <v-app-bar id="drag-block-bar"  dark elevation="5" style="z-index: 1">
              <v-icon class="mr-2">{{block.blockModel.icon}}</v-icon>
              <span>{{block.blockModel.label}}</span>
              <v-spacer></v-spacer>
              <v-icon @click="confOpen=false;">{{ $icons.close }}</v-icon>
            </v-app-bar>
          </v-card-title>

          <v-card-text class="pt-5">
            <component :is="block.blockModel.editComponent" :block="block"/>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- fenêtre insert block -->
      <v-dialog
          v-if="field"
          v-model="insertBlockOpen"
          max-width="800"
      >
        <v-card>
          <v-app-bar dark  class="mb-5">
            <span>Que souhaitez-vous intégrer ici ?</span>
            <v-spacer></v-spacer>
            <v-icon @click="insertBlockOpen=false;">{{ $icons.close }}</v-icon>
          </v-app-bar>
          <v-card-text>

            <v-list dense>

              <v-list-item-group>
                <template v-for="(blockModelType,i) of field.getLibrary()">

                  <v-list-item
                      v-if="blockModelType.type !== undefined"
                      :key="blockModelType.type"
                      @click="add(blockModelType)"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="blockModelType.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="blockModelType.label"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-subheader v-else :key="i">
                    {{blockModelType.label}}
                  </v-subheader>
                </template>

              </v-list-item-group>
            </v-list>

          </v-card-text>
        </v-card>
      </v-dialog>

    </div>

</div>
</template>

<script>
import AddBlockButtons from "@/ee09/db-ui/fields/blocks/add-block-buttons";
import BlocksField from "@/ee09/fields/BlocksField";
import BlockItem from "@/ee09/fields/BlockItem";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
gsap.registerPlugin(Draggable);
export default {
  name: "wysiwyg-block-wrapper",
  // eslint-disable-next-line vue/no-unused-components
  components: {AddBlockButtons},
  data:function(){
    return {
      hover:false,
      confOpen:false,
      insertBlockOpen:false,
      insertBefore:false
    }
  },
  props:{
    /**
     * Le champ de type BlocksField dans lequel ce block est utilisé
     */
    field:{
      description:"Le champ de type BlocksField dans lequel ce block est utilisé",
      type:BlocksField,
      mandatory:true,
      validate(){
        return true;
      }
    },
    block:{
      description:"Le block en question",
      type:BlockItem,
      mandatory:true,
      validate(){
        return true;
      }
    }
  },
  mounted(){
    //faut il ouvrir la conf au chargement ?
    if(this.wysiwygEnabled
        && this.$dbUi.forceEditBlock
        && this.$dbUi.forceEditBlock.uid === this.block.uid
        && this.block.blockModel.editComponent !== null
    ){
      this.$dbUi.forceEditBlock=null;
      this.focusBlock();
    }
  },
  computed:{
    wysiwygEnabled(){
      return this.$dbUi.preferences.wysiwygEnabled;
    },
    wysiwygVisible(){
      if(!this.wysiwygEnabled){
        return false;
      }
      return this.hover || this.confOpen || this.insertBlockOpen
    }
  },
  methods:{
    focusBlock(){
      this.confOpen=true;
      //this.$dbUi.drawer.focus=this.block;
    },
    add(blockModelType){
      let newBlock=this.field.addBlock(blockModelType,this.block,this.insertBefore);
      this.$dbUi.forceEditBlock=newBlock;
      this.insertBlockOpen=false;
    },
    initDrag(){
      //let me=this;
      setTimeout(function(){
        let $dialog=document.getElementById('singleBlockDialog').closest('.v-dialog')
        $dialog.classList.add("draggable");
        Draggable.create($dialog,{
          edgeResistance:0,
          inertia:false,
          trigger:"#drag-block-bar"
        })
      },100);

    }
  },
  watch:{
    confOpen(){
      if(this.confOpen){
        this.initDrag();
      }
    }
  }
}
</script>

<style lang="less">
.block-wrapper{
  position: relative;

  display: block;

  .wysiwyg-layer{
    pointer-events: none;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    >*{
      background-color: rgba(14, 222, 221, 0.34);
      pointer-events: auto;
    }
    >.top,>.bottom{
      position: absolute;
    }
    >.bottom{
      bottom: 0;
    }
    >.top{
      top: 0;
    }
    >.right{
      right: 0;
    }
    >.center{
      left: 50%;
    }

    outline: 1px dashed #808080 ;
    outline-offset: -2px;

  }

}
</style>