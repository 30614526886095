export default class EE09Navigation{
    /**
     * Reload the current window
     */
    reload(){
        document.location.reload();
    }
    openBlank(url){
        window.open(url, '_blank');
    }

    /**
     * Ouvre un tab et transmet $data sous forme de json stringifié via un e requête _POST
     * @param {String} url
     * @param {} data
     */
    openBlankWithPost(url, data) {
        let form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", url);
        form.setAttribute("target", "_blank");
        let input = document.createElement('input');
        input.type = 'hidden';
        input.name = "data";
        input.value = JSON.stringify(data);
        form.appendChild(input);
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }
}