<template>
    <v-btn
        class="record-create-btn"
        :class="cssClass"
           :icon="onlyIcon && !fab"
           :large="large"
           :small="small"
           :xLarge="xLarge"
           :xSmall="xSmall"
           :color="colorSmart.color" :dark="colorSmart.dark" :light="colorSmart.light"
           :fab="fab"
           @click.prevent="btnClick($event)"
    >
      <v-icon v-if="displayIcon" :left="!onlyIcon">{{icon}}</v-icon>
      <span v-if="text">{{text}} {{textStatus}}</span>

      <v-progress-circular
          v-if="displayProgress"
          v-model="progress"
          :indeterminate="progress === null || status==='md5'"
          :color="colorSmart.color"
          class="progress ml-2"
          size="16" width="3">
      </v-progress-circular>
      <input
          v-if="schema.isFile && displayFileInput"
          type="file" :multiple="multipleUploads" :accept="fileAccept"
          @click.stop="stopTheEvent"
          @change="uploadInputChange"
      >
    </v-btn>
</template>

<script>
export default {
  name: "record-create-btn",
  components: {},
  props:{
    large: {type:Boolean},
    dark: {type:Boolean},
    fab: {type:Boolean},
    small: {type:Boolean},
    xLarge: {type:Boolean},
    xSmall: {type:Boolean},
    text:{type:String,default:''},
    recordType: {type:String},
    color: {type:String},
    multipleUploads: {type:Boolean},
    fileAccept:{type:String,default: "*"},
  },
  data: () => ({
    displayFileInput:true,
    progress:undefined,
    status:undefined
  }),
  computed:{
    /**
     * @return {DbModelType}
     */
    schema(){
      return this.$db.settings.getModelType(this.recordType);
    },
    cssClass(){
      let c=[];
      if(this.schema.isFile){
        c.push("upload-wrap");
      }
      return c.join(" ");
    },
    icon(){
      let i=this.$icons.plusCircle;
      if(this.schema.isFile){
        i=this.$icons.upload;
      }
      return i;
    },
    displayIcon(){
      if(this.schema.isFile){
        switch (this.status){
          case "md5":
          case "uploading":
            return false;
        }
      }
      return true;
    },
    displayProgress(){
      if(this.schema.isFile){
        switch (this.status){
          case "md5":
          case "uploading":
            return true;
        }
      }
      return false;
    },
    onlyIcon(){
      return this.text==='';
    },
    textStatus(){
      if(this.status==="md5"){
        return `Analyse ${Math.floor(this.progress)}%`
      }
      return "";
    },
    colorSmart(){
      switch (this.status){
        case "md5":
          return {
            color:'orange',
            dark:true,
            light:false
          }
        case "uploading":
          return {
            color:'success',
            dark:true,
            light:false
          }
      }
      return {
        color:this.color,
        dark:false,
        light:true
      }
    }


  },
  methods:{
    /**
     * Appelé au click sur le bouton principal
     * @param event
     */
    btnClick(event){
      if(!this.schema.isFile){
        this.$emit('add-record',event)
      }
      this.stopTheEvent(event);

    },
    /**
     * Appelé au click sur l'input file'
     * @param event
     */
    uploadInputChange(event){
      let me=this;
      //Reset le file input
      this.displayFileInput=false;
      setTimeout(function(){
        me.displayFileInput=true;
      },100);

      for(let f of event.target.files){
        let task=this.$db.getFileRecord(f,this.recordType);
        task.on("STATUS_CHANGE",function(){
          console.warn("status change",task.status)
          me.status=task.status;
        })
        task.on("PROGRESS",function(){
          console.log("PROGRESS")
          me.progress=task.percent;
        })
        task.on("RESULT",
            /**
             *
             * @param {FileRecord} result
             */
            function(result){
              console.log("result",result)
              me.progress=0;
              me.status=null;
              me.$emit('set-record-file',event,result);
            }
        );
      }



    },
    stopTheEvent(event){
      event.stopPropagation();
    }


  }
}
</script>

<style lang="less">
  .record-create-btn{
    .progress{
      .v-progress-circular__overlay{
        transition-duration:0s !important;
      }
    }
    &.upload-wrap{
      overflow: hidden;
      //pointer-events: none;
      cursor: pointer;
      input[type='file']{
        display: block !important;
        //pointer-events: all;
        opacity: 0;
        text-indent: 10000px;
        appearance: none;
        background-color: yellow;
        position: absolute !important;
        width: 150% !important;
        height: 150% !important;
        max-width: 150% !important;
        max-height: 150% !important;

        top: -25%;
        left: -25%;


        cursor: pointer;
        z-index: 10;
      }
    }
  }

</style>