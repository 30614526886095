const uniqid = require('uniqid');
const _trim=require("lodash/trim");
/**
 * Quelques méthodes utilitaires courantes
 */
export default class EE09string {

    constructor() {
    }

    /**
     * Renvoie un identifiant unique
     * @param {string} prefix
     * @param {string} suffix
     * @return {string}
     */
    uniqId(prefix=null,suffix=null){
            return uniqid(prefix,suffix);
    }

    /**
     * Nettoie le texte des caractères spéciaux (autorise les slashes)
     * @param text
     * @return {string}
     */
    slugify (text,allowSlash=true) {
        text=text.toLowerCase();
        text=text.trim();
        if(!allowSlash){
            text=text.replaceAll("/","-");
        }
        text=text.replaceAll(/\s+/g, '-');
        text=text.replaceAll("$","-");
        text=text.replaceAll(".","-");
        text=text.replaceAll("'","-");
        text=text.replaceAll('"',"-");
        let a = '@šàáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·_,:;€*§&’!?#'
        let b = 'asaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh-------------'
        a=a.split("");
        b=b.split("");
        for(let i=0;i<a.length;i++){
            text=text.replaceAll(a[i],b[i]);
        }
        text=text.replaceAll(/--+/g, '-'); // Replace multiple - with single -

        text=this.trim(text,"- /");
        return text;
    }

    trim(string,char){
        return _trim(string,char)
    }
    /**
     * Renvoie un texte normalisé (minuscule et sans accents)
     * @param str
     * @return {string}
     */
    normalizeString(str){
        str=String(str);
        str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        str = str.toLowerCase();
        return str;
    }

    /**
     *
     * @param {string} str
     * @return {string}
     */
    noAccents(str){
        return str.normalize("NFD").replace(/\p{Diacritic}/gu, "")

    }

}
