<template>
  <v-dialog
      transition=""
      v-if="$dbUi.singleRecordOpen"
      v-model="$dbUi.singleRecordOpen"
      max-width="900"
      scrollable
      id="singleRecordDialogParent"

  >
    <v-card color="#fff"  id="singleRecordDialog">
      <v-app-bar id="drag-bar" dark elevation="5" style="z-index: 1">

        <record-list-item :record="$dbUi.singleRecord">
          <template v-slot:action>
            <btns-record-action :record="$dbUi.singleRecord"/>
            <v-icon class="ml-5" @click="$dbUi.singleRecord=null">{{$icons.close}}</v-icon>
          </template>
        </record-list-item>
      </v-app-bar>

      <v-card-text style="height: calc(100vh - 100px);" class="px-0">
        <component
            class=" mb-5"
            :record="$dbUi.singleRecord"
            :is="$db.settings.getModelType($dbUi.singleRecord.type).editComponent">
        </component>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
import RecordListItem from "@/ee09/db-ui/records/record-list-item";
import BtnsRecordAction from "@/ee09/db-ui/records/btns/btns-record-action";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
gsap.registerPlugin(Draggable);
export default {
  name: "admin-edit-record",
  components: {BtnsRecordAction, RecordListItem},
  mounted() {

  },
  computed:{
    isOpen(){
      return this.$dbUi.singleRecordOpen
    }
  },
  watch:{
    isOpen(){
      if(this.isOpen){
        this.initDrag();
      }
    }
  },
  methods:{
    initDrag(){
      //let me=this;
      setTimeout(function(){
        let $dialog=document.getElementById('singleRecordDialog').closest('.v-dialog')
        $dialog.classList.add("draggable");
        Draggable.create($dialog,{
          edgeResistance:0,
          inertia:false,
          trigger:"#drag-bar"
        })
      },1000);

    }
  }
}
</script>

<style lang="less">

  .v-dialog.draggable{
    transition-duration: 0s !important;
  }

</style>