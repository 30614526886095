import PageModel from "@/Models/PageModel";
import BlocksField from "@/ee09/fields/BlocksField";

export default class PagelocationModel extends PageModel{
    constructor() {
        super();
        /**
         *
         * @type {string}
         */
        this.type="pagelocation";

        /**
         * Le contenu pour la faire courte
         * @type {BlocksField}
         */
        this.blocks=new BlocksField([
            {
              label:"Rental"
            },
            "accordion-location-block",
            {
                label:"Autres"
            },
                "paragraph-block",
                "spacing-block",
        ],

        );



    }



}