<template>
<div class="nav-overlay">
  <nav class="bar"
  :class="{
    'hidden':$layout.nativeScroll.isDown,
    'is-scrolled':$layout.nativeScroll.isScrolled
  }"
  >
    <!--top logo-->
    <top-logo/>
    <button
        @click="$layout.navOpen=true"
        class="ty-link menu-btn">
      Menu
    </button>
  </nav>

  <div class="blocker" @click="$layout.navOpen=false"></div>
  <side-menu/>
  <!--social icos-->
  <secondary-nav/>

  <v-btn icon dark
         class="close"
         aria-label="Close Menu"
         @click.native="$layout.navOpen=false">
    <v-icon color="var(--color-project)" size="32">{{$icons.close}}</v-icon>
  </v-btn>

</div>
</template>

<script>
import SideMenu from "@/layout/side-menu";
import SecondaryNav from "@/layout/social-nav";
import TopLogo from "@/layout/top-logo";
export default {
  name: "nav-overlay",
  components: {TopLogo, SecondaryNav, SideMenu}
}
</script>

<style lang="less">
.nav-overlay{
  z-index: 50;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  >*{
    pointer-events: auto;
  }
  .bar{
    position: fixed;
    width: 100%;
    //outline: 1px solid red;
    outline-offset: -1px;
    padding: var(--nav-padding);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .top-logo{
      width: auto;
      height: var(--top-logo-h);
    }
    .menu-btn{
      //background-color: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(8px)   contrast(0.9);
      text-transform: uppercase;
      border: 1px solid var(--color-project);
      width: 122px;
      height: 40px;
    }
    transition: all 0.5s;
    &.is-scrolled{
      background-color: rgba(0, 0, 0, 0.5);
    }
    &.hidden{
      transform: translateY(-150px);
    }
  }



  .side-menu{
    transition: all 0.5s ease-in-out;
    position: fixed;
    z-index: 50;
    top: 0;
    right: calc(-1 * var(--side-menu-w));
    height: 100vh;
    min-width: var(--side-menu-w) !important;
  }

  .blocker{
    transition: all 0.5s ease-in-out;
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: saturate(0.2);
  }
  .social-nav{
    position: fixed;
    bottom: 0;
    z-index: 50;
    transition: all 0.75s ease-out;
    left: 100%;
    width: 100%;
    justify-content: flex-end;

    @media (min-width: @screen-lg){
      width: auto;
      justify-content: flex-start;
      left: 0;
      transform-origin: top left;
      transform: rotate(-90deg) translateY(40px);
      .btn-ico{
        transform: rotate(90deg);
      }
    }
  }

  .close {
    display: none;
  }

}

[nav-open]{
  .nav-overlay{
    .close{
      display: flex;
      position: fixed;
      top: var(--s4);
      right: var(--s4);
      z-index: 51;
    }


    .side-menu,.blocker{
      right: 0px;
    }
    .social-nav{
      left: auto;
      right: 0;
      width: var(--side-menu-w);
    }
    @media (min-width: @screen-lg){
      .social-nav{
        left: 0;
      }
    }
  }
}

[is-home]{
  .nav-overlay{
    .bar{
      //position: absolute;
    }
  }
}


</style>