<template>
  <div class="page">


    <!-- header -->
    <container-grid-size>

      <v-row>


        <!--gauche-->
        <v-col cols="12" order="2"
               md="8" offset-md="1" order-md="1">

          <!--baseline-->
          <div class="d-none d-md-block my-line-2"></div>
          <h2 class="h3">
            {{ rec.baseline }}
          </h2>
        </v-col>

        <!--droite-->
        <v-col cols="12" order="1"
               md="2" order-md="2" offset-md="1"
               class="py-md-0  text-right">
              <div class="d-none d-md-block mt-line-3"></div>
                <!--date-->
                <txt-date :date-field="rec.date_published"/>
        </v-col>
      </v-row>
    </container-grid-size>

    <!--blocks-->
    <blocks :blocks-field="rec.blocks"/>




  </div>
</template>

<script>
import RecordMixin from "@/ee09/db-ui/records/RecordMixin";

import ContainerGridSize from "@/components/atoms/container-grid-size";
import Blocks from "@/Models/blocks/blocks";
import TxtDate from "@/components/atoms/txt-date";

export default {
  name: "PostPage",
  components: {TxtDate, Blocks, ContainerGridSize},
  mixins:[RecordMixin],
  computed:{
    /**
     *
     * @return {PostModel}
     */
    rec(){
      return this.record
    }
  }
}
</script>

<style scoped>

</style>