import LinkItem from "@/ee09/models/LinkItem";
import ItemList from "@/ee09/models/ItemList";

export default class LinkItemList extends ItemList{
    /**
     *
     * @param {function} getNewItem La methode qui permet de créer un nouvel élément
     */
    constructor(getNewItem) {
        super(getNewItem);
        /**
         * La liste
         * @type {LinkItem[]}
         */
        this.items=[];
    }

    /**
     * Ne renvoie que les items qui sont ok
     * @return {LinkItem[]}
     */
    get itemsOk(){
        return this.items.filter(i=>i.isOk);
    }

    /**
     * Renvoie un nouvel item qui sera compatible avec cette liste
     * @return {LinkItem}
     */
    getNewItem(){
        return new LinkItem();
    }


    get hello(){
        return "hello from linkItem list "+Date.now();
    }
}