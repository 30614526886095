const merge=require('lodash/merge');

export default class EE09Item {
    constructor() {
        /**
         * unique id
         * @type {string}
         */
        this.uid=window.$db.utils.string.uniqId("it-");
        /**
         * Type d' item
         * @type {string}
         */
        this.itemType="EE09Item";
    }
    /**
     * Le getter permet de s'assurer que les données affichées soient bien celles issues d'un block et non simplement d'un json
     * @return {boolean}
     */
    get isInit(){
        return true;
    }
    get hello(){
        return "Hello from EE09Item";
    }

    /**
     * Charge les données dans l'objet
     * @param {*} data Les données issues du json
     */
    mount(data){
        //console.log("mount EE09Item",data)
        if(data){
            merge(this,data);
        }
    }
}