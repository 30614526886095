<!-- champ recherche et selection de colonnes pour les listes de recirds dans l'admin -->
<template>
  <v-row>
    <v-col  md="4">
      <v-text-field
          @input="$emit('search',search)"
          v-model="search"
          rounded filled
          :append-icon="$icons.search"
          label="Rechercher"
          clearable
          single-line
          hide-details
      />
    </v-col>
    <v-col>
      <v-select
          @input="$emit('selectColumns',columns)"
          label="Colonnes"
          multiple
          outlined
          v-model="columns"
          :items="possibleColumns"
          :menu-props="{
            closeOnClick: true,
            closeOnContentClick: true,
          }"
      >

        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index < 2" small>
            <span>{{ item.text }}</span>
          </v-chip>
          <span
              v-if="index === 2"
              class="grey--text text-caption"
          >
          (+{{ columns.length - 2 }})
        </span>
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "table-header",
  data:function(){
    return {
      search:"",
      columns: []
    }
  },
  props:{
    possibleColumns:{
      type:Array
    },
    selectedColumns:{
      type:Array
    }
  },
  mounted() {
    this.columns=this.selectedColumns
  }

}
</script>

<style scoped>

</style>