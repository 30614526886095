<template>
  <div class="page page-page">
    <div class="poster-placeholder"
         :data-poster-size="$layout.pageBg.size"/>
    <v-container class="over-poster py-0">
      <h1 class="ty-title-page"
          style="white-space: pre-line;">
        {{rec.pagetitle?rec.pagetitle:rec.name}}
      </h1>
    </v-container>
    <section>
      <blocks :blocks-field="recPage.blocks" />
      <v-container>
        <prev-next
            :prev="$layout.prevPage"
            :next="$layout.nextPage"
            class="my-10"
        />
      </v-container>
    </section>

    <marquee-text

        class="txt-scroll ty-title-paragraph"
        :repeat="10" :duration="10">
      [ {{this.recPage.name}} ] &nbsp;
    </marquee-text>

  </div>

</template>

<script>
import RecordMixin from "@/ee09/db-ui/records/RecordMixin";
import Blocks from "@/Models/blocks/blocks";
import PrevNext from "@/pic/prev-next";
import MarqueeText from 'vue-marquee-text-component'

export default {
  name: "PagePage",
  components: {
    PrevNext,
    Blocks,
    MarqueeText
  },
  mixins:[RecordMixin],
  mounted() {
    this.$layout.pageBg.setCurrentPage(this.recPage,this.$layout.pageBg.SIZE_BANDEAU);
  },
  computed:{ }
}
</script>

<style lang="less">
.page-page{

  .poster-placeholder{
    >*{
      //justify-content: start;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .over-poster{
    position: relative;
    //outline: 1px solid red;
    //margin-top: calc( var(--s0) * -1.2 );
    //margin-bottom: var(--s0);
    >h1{
      position: absolute;
      width: 100%;
      //top: 0;
      //left: 0;
      bottom: calc( 100% - var(--s0) * 0.4);
    }
    padding-top: calc( var(--s0) * 0.6 ) !important;
  }
  >section{
    //TODO nécassaire quand on scrolle mais pas pdt la transition
    background-color: black;
  }

  .txt-scroll{
    pointer-events: none;
    //outline: 1px solid red;
    outline-offset: -1px;
    position: fixed;
    bottom: 0;
    left: 100%;
    transform-origin: bottom left;
    transform: rotate(-90deg) translateY(-50px);
    width: 100vh;
    display: none;
    @media(min-width: @screen-md){
      display: block;
    }
  }

}


</style>