<template>
  <v-dialog
      light
      class="admin-dialog"
      v-model="$dbUi.visible"
      fullscreen
      hide-overlay
      scrollable
      transition="dialog-top-transition"
  >

    <admin-dialog-nav/>

    <div class="admin-dialog-content">
      <v-card class="ma-10 pa-5">
        <v-card-text>
          <!-- liste de records -->
          <admin-records-table  v-if="$dbUi.currentScreen==='records'"/>
          <!-- settings -->
          <json-db-settings     v-if="$dbUi.currentScreen==='settings'"/>
          <!-- SEO -->
          <seo-dashboard        v-if="$dbUi.currentScreen==='seo-dashboard'"/>

        </v-card-text>
      </v-card>

    </div>

  </v-dialog>
</template>

<script>


import JsonDbSettings from "./json-db-settings";
import AdminDialogNav from "./nav/admin-dialog-nav";
import SeoDashboard from "@/ee09/db-ui/seo-dashboard";
import AdminRecordsTable from "@/ee09/db-ui/admin-records-table";
export default {
  name: "admin-dialog",
  components: {AdminRecordsTable, SeoDashboard, AdminDialogNav, JsonDbSettings},
  data () {
    return {
      notifications: false,
      sound: true,
      widgets: false,
    }
  },
  methods:{}
}
</script>

<style lang="less">

    .admin-dialog-content,.admin-dialog-nav{
      --left-w:300px;
      --top-h:65px;
    }

    .admin-dialog-nav{

      >.top,.left{
        z-index: 1;
        position: fixed;
      }
      >.top{
        top: 0;
        width: 100%;
      }
      >.left{
        overflow-y: auto;
        width: var(--left-w);
        top: var(--top-h);
        height: calc( 100% - var(--top-h));
        >.wrap{
          display: flex;
          flex-direction: column;
          min-height:100%
        }
        background-color: #F5F5F5;
        .bottom{
          background-color: #F5F5F5;
          width: 100%;

        }
        overflow-y: auto;
      }
    }
    .admin-dialog-content{
      background-color: #DFDFDF;
      position: absolute;
      min-height: calc( 100vh - var(--top-h));
      padding-left: var(--left-w);
      top: var(--top-h);
      margin-top: -1px;
      //width: calc(100% - 300px);

      --display-is-big:none;
      --display-is-small:inline;
      @media(min-width: @screen-md){
        --display-is-big:inline;
        --display-is-small:none;
      }


    }

</style>