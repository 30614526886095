import RecordMeta from "@/ee09/records/RecordMeta";

const merge=require('lodash/merge');
import DateField from "../fields/DateField";
export default class DbRecord{
    constructor() {
        this.type="dbRecord";
        /**
         * id unique dans la base de données, composé par type-id
         * @type {null|String}
         */
        this.uid=null;
        /**
         * Identifiant texte pour les humains du record
         * @type {string}
         */
        this.name="";
        /**
         * Date de création du record
         * @type {DateField}
         */
        this.date_created=new DateField();
        /**
         * Date de la dernière modification du record
         * @type {DateField}
         */
        this.date_modified=new DateField();
        /**
         * Des variables libres (non processée, juste du json)
         * @type {{}}
         */
        this.extravars={}
    }

    /**
     * texte normalisé (sans acccents en minuscules) pour effectuer des recherches
     * @return {string}
     */
    get keywordsSearch(){
        return window.$utils.string.normalizeString(this.name);
    }
    /**
     * Juste pour tester si le record et donc ses modèles hérités sont une page ou non
     * @readonly
     * @return {boolean}
     */
    get isPage(){
        return false;
    }

    /**
     * Les meta du record
     * @return {RecordMeta}
     */
    get meta(){
        let meta=window.$db.recordsMeta.find(m=>m.uid===this.uid);
        if(!meta){
            meta=new RecordMeta(this);
            window.$db.recordsMeta.push(meta);
        }
        return meta;
    }
    /**
     * Méthode exécutée juste après que le record soit chargé
     * @private
     */
    _processData(){
        // eslint-disable-next-line no-unused-vars
        Object.entries(this).forEach(([fieldName,fieldValue])=>{
            //console.log("field",fieldName,fieldValue.constructor.name )
            if(fieldValue && typeof fieldValue._processData === "function"){
                fieldValue._processData(this);
            }
        })
    }
    /**
     * Charge les données dans l'objet
     * @param {*} data Les données issues du json
     */
    mount(data){
        if(data){
            let me=this;
            merge(this,data);
            this._processData();
            //petit delay pour le modified afin d'éviter les récusions du watcher
            //setTimeout(function(){
                me.meta.json=JSON.stringify(data);
                me.meta.modified=false;
            //},1000);

        }
    }



    /**
     * Preview record Visible que dans l'admin (sous name)
     * @return {null|string}
     */
    get adminSubtitle(){
        if(window.$dbUi.preferences.debugMode && this.uid){
            let s=this.meta.dataSize;
            let human=window.$db.utils.file.humanSize(s);
            return ""+human+ " | " +this.uid;
        }else{
            return this.modelType.label;
        }

    }
    /**
     * Preview Record Une icone visible que dans l'admin (sous name)
     * @return {null|string}
     */
    get adminSubtitleIcon(){
        return null;
    }

    /**
     * Preview Record Une thumbnail visible uniquement dans l'admin
     * @return {null|string}
     */
    get adminThumb(){
        return null;
    }



    /**
     * Appelé quand on fait un store
     */
    update(){
        this._setUid();
        //this.date_modified.date=new Date();
    }

    /**
     * Définit un uid au record si il n'en a pas encore
     * @private
     */
    _setUid(){
        if(!this.uid) {
            this.isNew=true;
            this.uid=window.$db.utils.string.uniqId(this.type+"-");
        }
    }

    /**
     * Renvoie la dbUidString de ce record (si il est enregistré)
     * @return {string|null}
     */
    get dbUidString(){
        if(this.uid){
            return "db:"+this.uid;
        }
        return null;
    }

    /**
     * Renvoie les propriétés du modèle
     * @return {DbModelType}
     */
    get modelType(){
        return window.$db.settings.getModelType(this.type);
    }

    /**
     * Les erreurs détectées sur ce record
     * @return {String[]}
     */
    get errors(){
        return this.errorsMeta;
    }
    /**
     * Les erreurs détectées sur ce record
     * @return {String[]}
     */
    get errorsMeta(){
        let r=[];
        if(!this.name){
            r.push("Le nom est obligatoire!");
        }
        return r;
    }

    /**
     * Permet de savoir si on peut par exemple supprimer un enregistrement ou non
     * @return {{rename: *, delete: *}}
     */
    get preventAction(){
        return {
            "delete":this.extravars.preventDelete,
            "rename":this.extravars.preventRename,
        }
    }


}