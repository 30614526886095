import LinkItem from "@/ee09/models/LinkItem";
import BlockItem from "@/ee09/fields/BlockItem";
import LinkItemList from "@/ee09/models/LinkItemList";




/**
 * Une liste de link item
 */
export default class CoreLinkItemListBlock extends BlockItem{
    constructor() {
        super();
        this.blockType="core-link-item-list-block";
        /**
         * Le lien
         * @type {LinkItemList}
         */
        this.list=new LinkItemList(
            function(){
                return new LinkItem();
            }
        );
    }

    mount(data) {
        super.mount(data);
        //console.log("mount CoreLinkItemListBlock",data)
        this.list._processData();
    }

    /**
     * Renvoie uniquement les liens qui sont ok
     * @return {LinkItem[]}
     */
    get itemsOk(){
        return this.list.items.filter(item=>item.isOk);
    }

}