import EE09Navigation from "@/ee09/utils/EE09Navigation";
import EE09string from "@/ee09/utils/EE09string";
import EE09arrayUtils from "@/ee09/utils/EE09arrayUtils";
import EE09MathUtils from "@/ee09/utils/EE09MathUtils";

export default class EE09utilsBrowser{
    constructor() {
        this.array=new EE09arrayUtils();
        this.math=new EE09MathUtils();
        this.navigation=new EE09Navigation();
        this.string=new EE09string();

    }
}