import BlockItem from "@/ee09/fields/BlockItem";

export default class ParagraphBlock extends BlockItem{

    constructor() {
        super();
        this.blockType="paragraph-block";
        /**
         * Afficher sur deux colonnes ou pas
         * @type {boolean}
         */
        this.twoCols=false;
        /**
         * Le texte
         * @type {string}
         */
        this.text="";
        /**
         * La deuxième colonne de texte
         * @type {string}
         */
        this.text2="";

        /**
         * Classe css à utiliser pour afficher ce texte
         * @type {string}
         */
        this.size="";

        /**
         * Afficher sous forme d'encart (ou pas)
         * @type {boolean}
         */
        this.framed=false;
        /**
         * Le titre n'est visible qu'en mode framed = true
         * @type {string}
         */
        this.title="";



    }

    mount(data) {
        super.mount(data);
    }


    get warning(){
        if(this.twoCols===true && (this.text.length>600 || this.text2.length>600) ){
            return "Sur les textes longs, il n'est pas conseillé d'utiliser deux colonnes";
        }
        return null;
    }

    hello() {
        alert("hello je suis un paragraphe")
    }
}
