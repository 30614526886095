<template>
  <!-- on met une key pour s'éviter un watcher-->
  <time
      :key="dateField.isoString"
      :datetime="seo"
      class="txt-label"
      >
    {{displayText}}
  </time>
</template>

<script>
export default {
  name: "txt-date",
  props:{
    dateField:{
      type:Object
    }
  },
  computed:{
    displayText(){
      /**
       *
       * @type {DateField}
       */
      let d=this.dateField;
      return d.format("dd/MM/yyyy");
    },
    seo(){
      /**
       *
       * @type {DateField}
       */
      let d=this.dateField;
      return d.format("yyyy-MM-dd HH:mm");
    }
  }
}
</script>

<style scoped>

</style>