<template>
<div>
  <table-header
       @search="search=$event"
       @selectColumns="colonnes=$event"
       :selected-columns="colonnes"
       :possible-columns="[
              {
                text:'Type',
                value:'type',
              },
              {
                text:'Date de modification',
                value:'date_modified'
              },
              {
                text:'Date de création',
                value:'date_created'
              },
              {
                text:'Erreurs',
                value:'errors-details'
              }
              ]"
  />

  <v-data-table
      @click:row="clickRow"
      sort-by="date_created"
      sort-desc
      :search="search"
      :custom-filter="customSearch"
      :disable-pagination="false"
      :hide-default-footer="false"
      :headers="headers"
      mobile-breakpoint="md"
      :items="lines">

    <template v-slot:item.name="{ item }">
      <div style="min-width: 300px" class="py-5">
        <record-list-item :record="item" class="pl-0"/>
      </div>
    </template>
    <template v-slot:item.type="{ item }">
      {{item.modelType.label}}
    </template>
    <template v-slot:item.date_modified="{ item }">
        <table-field-date :date-field="item.date_modified"/>
    </template>
    <template v-slot:item.date_created="{ item }">
      <table-field-date :date-field="item.date_created"/>
    </template>
    <template v-slot:item.date_published="{ item }">
      <table-field-date :date-field="item.date_published"/>
    </template>
    <template v-slot:item.errors-details="{ item }">
      <div style="max-width: 200px" class="py-5" v-if="item.errors.length">
        <v-alert color="error" dense dark class="mb-0">
          <div v-for="(err,i) of item.errors" :key="i" class="caption">
            {{err}}
          </div>
        </v-alert>
      </div>
    </template>
    <template v-slot:item.errors="{ item }">
      <table-fields-errors-actions :record="item"/>

    </template>

  </v-data-table>


</div>
</template>

<script>
import RecordListItem from "@/ee09/db-ui/records/record-list-item";
import TableFieldDate from "@/ee09/db-ui/table-fields/table-field-date";
import TableHeader from "@/ee09/db-ui/table-fields/table-header";
import TableFieldsErrorsActions from "@/ee09/db-ui/table-fields/table-fields-errors-actions";
export default {
  name: "admin-records-table",
  components: {TableFieldsErrorsActions, TableHeader, TableFieldDate, RecordListItem},
  data(){
    return {
      test:true,
      //les colonnes affichées par défaut
      colonnes:['date_created'],
      search:'',
    }
  },
  computed:{
    headers(){
      let h=[
        {
          text: 'Nom',
          align: 'start',
          sortable: true,
          value: 'name',
        }
      ];
      if(this.colonnes.indexOf('type')>-1){
        h.push(
            {
              text: 'Type',
              value: 'type'
            }
        );
      }
      if(this.colonnes.indexOf('date_created')>-1){
        h.push(
            {
              text: 'Créé',
              value: 'date_created' ,
              sort: (a, b) => a.date.getTime()-b.date.getTime()
            }
        );
      }
      if(this.colonnes.indexOf('date_modified')>-1){
        h.push(
            {
              text: 'Modifié',
              value: 'date_modified',
              sort: (a, b) => a.date.getTime()-b.date.getTime()
            }
        );
      }
      if(this.colonnes.indexOf('date_published')>-1){
        h.push(
            {
              text: 'Publié',
              value: 'date_published' ,
              sort: (a, b) => a.date.getTime() - b.date.getTime()
            }
        );
      }
      if(this.colonnes.indexOf('errors-details')>-1){
        h.push(
            {
              text: 'Erreurs',
              value: 'errors-details',
              sortable: false
            }
        );
      }
      h.push(
          {
            align: "end",
            text: '',
            value: 'errors',
            sort: (a, b) => a.length - b.length
          }
      );
      return h;
    },
    /**
     *
     * @return {DbRecord[]}
     */
    lines(){
      return this.$db.getListType(this.$dbUi.currentRecordsTypes)
    }
  },
  methods:{
    clickRow(record){
      this.$dbUi.displayRecord(record)
    },
    /**
     *
     * @param {any} value
     * @param {string} search
     * @param {DbRecord} item
     * @return {boolean}
     */
    customSearch (value, search, item) {
      let me=this;
      search=this.$utils.string.normalizeString(search);
      //return item.keywordsSearch.includes(search);
      return Object.values(item).some(
          v=> v && me.$utils.string.normalizeString(v).includes(search)
      );

    },



  }
}
</script>

<style scoped>

</style>