<template>
<div class="footer-contact
py-4 px-3
py-md-16 px-md-5">
  <v-row>
    <!-- adresse -->
    <v-col cols="12"
           sm="5" offset-sm="0"
           md="5" offset-md="1"
    >
      <address class="ty-rich-text"
               v-html="$layout.footer.getStringHtml('Adresse')">
      </address>
    </v-col>
    <!-- petite flèche-->
    <v-col cols="3"
           sm="2"
           md="2"
           lg="1"
    >
      <span class="ty-title-link text-no-wrap">-></span>
    </v-col>
    <!-- mail / phone -->
    <v-col  cols="9"
            sm="4"
            md="4"
           class="has-href-underline ty-link d-flex">
      <div class="pt-md-5">
        <a :href="`mailto:${$layout.footer.getString('Contact Mail')}`">{{ $layout.footer.getString("Contact Mail") }}</a><br>
        <a :href="`tel:${$layout.footer.getString('Contact Phone')}`">{{ $layout.footer.getString("Contact Phone") }}</a>
      </div>
    </v-col>
  </v-row>
</div>
</template>

<script>
export default {
  name: "footer-contact"
}
</script>

<style lang="less">
.footer-contact{
  border: 1px solid var(--color-light);
  margin-bottom: 40px;
  @media(min-width: @screen-sm){
    margin-bottom: 60px;
  }
  @media(min-width: @screen-lg){
    margin-bottom: 80px;
  }
  @media(min-width: @screen-xl){
    margin-bottom: 120px;
  }
}
</style>