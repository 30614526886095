<template>
<v-container class="grid-size">
  <slot/>
</v-container>
</template>

<script>
/**
 * Il s'agit d'un simple container dont la taille correspond au templates sous forme de grille
 */
export default {
  name: "container-grid-size"
}
</script>