var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table-header',{attrs:{"selected-columns":_vm.colonnes,"possible-columns":[
              {
                text:'Description',
                value:'description',
              },
              {
                text:'Priorité',
                value:'priority'
              },
              {
                text:'Fréquence',
                value:'changefreq'
              },
              {
                text:'URL',
                value:'slug'
              },
              {
                text:'Canonical',
                value:'canonical'
              },
              {
                text:'JSON',
                value:'jsonld'
              },
              {
                text:'Erreurs',
                value:'errors-details'
              }

              ]},on:{"search":function($event){_vm.search=$event},"selectColumns":function($event){_vm.colonnes=$event}}}),_c('v-data-table',{staticClass:"table-seo",attrs:{"sort-by":"slug","search":_vm.search,"disable-pagination":false,"hide-default-footer":false,"headers":_vm.headers,"mobile-breakpoint":"md","items":_vm.lines},on:{"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"py-5",staticStyle:{"width":"350px"}},[_c('div',[_vm._v(_vm._s(item.title))]),_c('record-list-item',{attrs:{"record":item.recordPage}})],1)]}},{key:"item.slug",fn:function(ref){
              var item = ref.item;
return [_c('code',[_vm._v(_vm._s(item.slug))])]}},{key:"item.description",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"py-5",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.jsonld",fn:function(ref){
              var item = ref.item;
return [(item.jsonld)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.$icons.check))]):_vm._e()]}},{key:"item.errors-details",fn:function(ref){
              var item = ref.item;
return [(item.errors.length)?_c('div',{staticClass:"py-5",staticStyle:{"max-width":"200px"}},[_c('v-alert',{staticClass:"mb-0",attrs:{"color":"error","dense":"","dark":""}},_vm._l((item.errors),function(err,i){return _c('div',{key:i,staticClass:"caption"},[_vm._v(" "+_vm._s(err)+" ")])}),0)],1):_vm._e()]}},{key:"item.errors",fn:function(ref){
              var item = ref.item;
return [_c('table-fields-errors-actions',{attrs:{"errors":item.erros,"record":item.recordPage}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }