<template>
<div>
  <!-- si le record est nouveau -->
  <v-btn v-if="!rec.uid"
         color="success" tile small
         @click="create"
  >
    Créer
  </v-btn>

  <!-- si le record existe déjà -->

  <v-btn v-if="rec.uid && rec.modelType.copiable"
         color="success" tile small
         @click="duplicate"
  >
    Copier
  </v-btn>

  <v-btn v-if="rec.uid"
         :class="{
              'ml-2':!icons,
              '':icons
         }"
         color="error"
         :tile="!icons"
         :small="!icons || small"
         :icon="icons"
         :disabled="rec.preventAction.delete"
         @click="$dbUi.trashAskRecord(rec)"
  >
    <v-icon small left v-if="rec.preventAction.delete">{{$icons.lock}}</v-icon>
    <span>Supprimer</span>
  </v-btn>

  <v-btn v-if="rec.uid"
         :class="{
              'ml-2':!icons,
              'ml-n2':icons,
              'ee09-admin-blink':rec.meta.modified
         }"
         :disabled="!rec.meta.modified"
         color="success"
         :tile="!icons"
         :small="!icons"
         :icon="icons"
         @click.stop="$db.store(rec,function(){});"
  >
    <span>Enregistrer</span>
  </v-btn>

  <!-- lien vers la page-->
  <btn-icon-aller-sur-la-page
      v-if="recPage.isPage && recPage.uid !== $layout.currentPage.uid"
      :record="record"
  />




</div>
</template>

<script>
import RecordMixin from "@/ee09/db-ui/records/RecordMixin";
import BtnIconAllerSurLaPage from "@/ee09/db-ui/records/btns/btn-icon-aller-sur-la-page";

export default {
  name: "btns-record-action",
  components: {BtnIconAllerSurLaPage},
  mixins:[RecordMixin],
  props:{
    small:{
      type:Boolean
    },
    icons:{
      type:Boolean
    }
  },
  methods:{
    create(){
      let me=this;
      this.$db.store(this.rec,function(data){
        console.log("created",data.body["saved-uid"]);
        me.$dbUi.displayRecord(me.$db.getByUid(data.body["saved-uid"]))
      });
      //this.$dbUi.displayRecords(rec.type)
    },
    duplicate(){
      let me=this;
      this.$db.duplicate(this.rec,function(copied){
        console.log("copied",copied);
        me.$dbUi.displayRecord(copied)
      },
      function(d){
        console.error("erreur lors de la copie",d)
      })
    }
  }
}
</script>
<style lang="less">

</style>
