<template>
  <div class="page-poster">
    <img
        v-if="image"
        :alt="recPage.thumbnail.alt"
        :src="image"/>
    <video v-if="isVideoOk"
        :src="recPage.videoposter.href"
        preload="metadata"
        muted loop playsinline
    />
    <!-- si video pas jouable-->
    <video v-else
        muted loop playsinline
    />
  </div>

</template>

<script>
import RecordMixin from "@/ee09/db-ui/records/RecordMixin";

export default {
  name: "page-poster",
  mixins:[RecordMixin],
  computed:{
    isVideoOk(){
      return this.recPage
      && this.recPage.videoposter
      && this.recPage.videoposter.isOk
    },
    image(){
      if(this.recPage && this.recPage.thumbnail && this.recPage.thumbnail.isOk){
        return this.recPage.thumbnail.resize(
            1600,
            1600,
            'contain',
            '000000',80,'webp'
        )
      }
      return null;
    }
  }
}
</script>

<style lang="scss">
.page-poster{
  position: relative;
  background-color: #000;
  img{
    opacity: 0.9;
  }
  video,img{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}
</style>