var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table-header',{attrs:{"selected-columns":_vm.colonnes,"possible-columns":[
              {
                text:'Type',
                value:'type',
              },
              {
                text:'Date de modification',
                value:'date_modified'
              },
              {
                text:'Date de création',
                value:'date_created'
              },
              {
                text:'Erreurs',
                value:'errors-details'
              }
              ]},on:{"search":function($event){_vm.search=$event},"selectColumns":function($event){_vm.colonnes=$event}}}),_c('v-data-table',{attrs:{"sort-by":"date_created","sort-desc":"","search":_vm.search,"custom-filter":_vm.customSearch,"disable-pagination":false,"hide-default-footer":false,"headers":_vm.headers,"mobile-breakpoint":"md","items":_vm.lines},on:{"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"py-5",staticStyle:{"min-width":"300px"}},[_c('record-list-item',{staticClass:"pl-0",attrs:{"record":item}})],1)]}},{key:"item.type",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.modelType.label)+" ")]}},{key:"item.date_modified",fn:function(ref){
              var item = ref.item;
return [_c('table-field-date',{attrs:{"date-field":item.date_modified}})]}},{key:"item.date_created",fn:function(ref){
              var item = ref.item;
return [_c('table-field-date',{attrs:{"date-field":item.date_created}})]}},{key:"item.date_published",fn:function(ref){
              var item = ref.item;
return [_c('table-field-date',{attrs:{"date-field":item.date_published}})]}},{key:"item.errors-details",fn:function(ref){
              var item = ref.item;
return [(item.errors.length)?_c('div',{staticClass:"py-5",staticStyle:{"max-width":"200px"}},[_c('v-alert',{staticClass:"mb-0",attrs:{"color":"error","dense":"","dark":""}},_vm._l((item.errors),function(err,i){return _c('div',{key:i,staticClass:"caption"},[_vm._v(" "+_vm._s(err)+" ")])}),0)],1):_vm._e()]}},{key:"item.errors",fn:function(ref){
              var item = ref.item;
return [_c('table-fields-errors-actions',{attrs:{"record":item}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }