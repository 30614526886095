<template>
  <svg width="0" height="0">
    <defs>
      <!-- dégradé du vert au bleu -->
      <linearGradient id="svg-blue-gradient" x1="70%" y1="0%" x2="30%" y2="100%" >
        <stop offset="10%" style="stop-color:rgb(14,222,221);stop-opacity:1.00" />
        <stop offset="100%" style="stop-color:rgb(85,86,238);stop-opacity:1.00" />
      </linearGradient>
    </defs>
    <rect width="300" height="300" fill="url(#svg-blue-gradient)"></rect>
  </svg>
</template>

<script>
export default {
  name: "svg-lib"
}
</script>

<style lang="less">
#svg-blue-gradient{
  //dégradé svg
}
</style>