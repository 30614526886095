export default class EE09arrayUtils {
    /**
     * Renvoie une copie du tableau melangée de manière pseudo aléatoire
     * @param {Array} array
     * @param {String|Number} seed
     * @return {*}
     */
    shuffleSeed(array,seed){
        let m = array.length, t, i;
        // While there remain elements to shuffle…
        while (m) {
            // Pick a remaining element…
            i = Math.floor(this._randomSeed(seed) * m--);
            // And swap it with the current element.
            t = array[m];
            array[m] = array[i];
            array[i] = t;
            ++seed;
        }
        return array;
    }

    _randomSeed(seed){
        seed=window.$db.utils.number.randomSeed(seed);
        let x = Math.sin(seed++) * 10000;
        return x - Math.floor(x);
    }

    /**
     * Mêlange un tableau
     * @param arr
     * @returns {Array}
     */
    shuffle(arr){
        return arr.sort(function(){return 0.5 - Math.random()})
    }

    /**
     * Renvoie un élément aléatoire du tableau
     * @param arr
     * @returns {null|*}
     */
    randomItem(arr){
        if(arr.length>0){
            return this.shuffle(arr)[0];
        }
        return null;

    }

    /**
     * Renvoie une copie du tableau dans le sens inverse
     * @param {[]} array
     * @return {[]}
     */
    reverse(array){
        return [].concat(array).reverse()
    }

    /**
     * Renvoie l'élément après currentEntry dans le tableau
     * @param {*} entry
     * @param {Array} array
     * @param {boolean} loop Si true boucle à la fin de la liste
     * @return {*|null}
     */
    next(entry,array,loop=false){
        let found=false;
        for(let p of array){
            if(found){
                return p;
            }
            if(JSON.stringify(p)===JSON.stringify(entry)){
                found=true;
            }
        }
        if(loop){
            return array[0]
        }
        return null
    }

    /**
     * Renvoie l'élément avant currentEntry dans le tableau
     * @param {*} entry
     * @param {Array} array
      * @param {boolean} loop Si true boucle à la fin de la liste
     * @return {*|null}
     */
    prev(entry,array,loop){
        return this.next(entry,this.reverse(array),loop);
    }

}
