import RecordField from "./RecordField";
//import TranslatedField from "./TranslatedField";


export default class ImageField extends RecordField{

    /**
     *
     * @param {boolean} isFileRecord Permet distinguer l'utilisation du champ dans un file record directement ou dans un champ standard
     */
    constructor(isFileRecord=false) {
        super();
        this._x=0.5;
        this._y=0.5;
        this._alt="";
        this._title=""

        /**
         * Permet de savoir si il s'agit du file record ou d'un champ qui y fait référence
         * @return {boolean}
         */
        this.isFileRecord=function(){
            return isFileRecord;
        }
        let cache={};
        this.getCache=function (prop){
            return cache[prop];
        }
        this.setCache=function(prop,val){
            cache[prop]=val;
        }

    }

    /**
     * Fait un proxy entre le champ et le record qui peut contenir des valeurs par défaut
     * @private
     * @param prop
     * @param fromRecord
     * @return {*}
     */
    getProp(prop,fromRecord=false){
        let _prop="_"+prop;
        if(this.isFileRecord()){
            return this[_prop];
        }
        if(fromRecord && this.record){
            //this[_prop]=""; //on vide le champ au cas où...
            return this.record.imagedata[prop];
        }
        if(!this[_prop] && this.record){
            return this.record.imagedata[prop];
        }
        return this[_prop];
    }
    setProp(prop,value,storeInRecord=false) {
        let _prop="_"+prop;
        this[_prop] = value;
        if(this.isFileRecord()){
            this[_prop] = value;
            return;
        }
        if(storeInRecord){
            if(this.record){
                this.record.imagedata[prop]=value;
                this.record.meta.modified=true;
                return;
            }
        }


    }


    get alt() {
        return this.getProp("alt",true);
    }

    set alt(value) {
        this.setProp("alt",value,true);
    }
    get title() {
        return this.getProp("title",true)
    }
    set title(value) {
        this.setProp("title",value,true);
    }
    get x() {
        return this.getProp("x")
    }
    set x(value) {
        this.setProp("x",value);
    }
    get y() {
        return this.getProp("y")
    }
    set y(value) {
        this.setProp("y",value);
    }


    /**
     * Renvoie le record file relatif
     * @return {FileRecord|null}
     */
    get record(){
        return super.record;
    }
    set record(record){
        if(record && record.isImage){
            this.uid=record.uid;
            //this._processData();
        }else{
            this.uid="";
        }

    }

    /**
     * Renvoie le chemin permettant de charger l'image
     * @return {string}
     */
    get href(){
        let r=this.record;
        if(r){
            return r.href;
        }
        return "";
    }

    get isOk(){
        if(this.record){
            return this.record.isOk;
        }
        return false;
    }

    /**
     * Renvoie l'url d'une image redimensionnée
     * @param {Number} width
     * @param {Number} height
     * @param {'contain'|'cover'} mode
     * @param {'contain'|string} backgroundColor
     * @param {Number} quality 0 to 100
     * @param {'jpg'|'png'|'webp'|null} extension
     * @return {string|null}
     */
    resize(width,height,mode="contain",backgroundColor="transparent",quality=80,extension=null){
        if(!this.record){
            return `${window.$api.serverUrl}/im/empty/im-${width}x${height}-${mode}-${backgroundColor}-${quality}.${extension}`;
        }
        return this.record.resize(
            width,
            height,
            mode,
            backgroundColor,
            quality,
            extension
        )
    }

    get errors(){
        let r=[]
        if(this.isOk){
            if(!this.alt){
                r.push("ALT manquant")
            }
        }
        return r;
    }




}