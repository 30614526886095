import EE09Item from "./EE09Item";
import ImageField from "@/ee09/fields/ImageField";
import LinkItem from "@/ee09/models/LinkItem";

export default class ImageItem extends EE09Item{
    constructor(imageField) {
        super();
        this.itemType="ImageItem"
        /**
         * L'image
         * @type {ImageField}
         */
        this.image=new ImageField();
        if(imageField){
           this.image=imageField;
        }
        /**
         * Lien pouvant être associé à l'image
         * @type {LinkItem}
         */
        this.link=new LinkItem();
        /**
         * Légende de l'image
         * @type {string}
         */
        this.text="";
    }

    /**
     * Est ok ou pas ?
     * @return {boolean}
     */
    get isOk(){
        return this.image.isOk;
    }
}