
import PageWithRelatedsModel from "@/Models/PageWithRelatedsModel";

export default class PostModel extends PageWithRelatedsModel{
    constructor() {
        super();
        /**
         *
         * @type {string}
         */
        this.type="post";
        /**
         * Titre de niveau 2
         * @type {string}
         */
        this.baseline="";
    }

    get errorsMeta(){
        let r=super.errorsMeta;
        if(!this.baseline){
            r.push("Pas de sous titre")
        }
        return r;
    }
}