

export default class PageUrl{
    constructor() {
        /**
         * uid du pageurl
         * @type {string}
         */
        this.uid = "";
        /**
         * id du pageurl
         * @type {string}
         */
        this.id = "";
        /**
         * La page liée
         * @type {string}
         */
        this.pageuid = "";
        /**
         * Url de la page
         * @type {string}
         */
        this.slug="";
        /**
         *
         * @type {string}
         */
        this.title="";
        /**
         *
         * @type {string}
         */
        this.description="";
        /**
         *
         * @type {string}
         */
        this.changefreq=PageUrl.CHANGE_FREQ_YEARLY;
        /**
         *
         * @type {number}
         */
        this.priority=0.3;
        /**
         *
         * @type {boolean}
         */
        this.ishome=false;
        /**
         *
         * @type {boolean}
         */
        this.hideid=false;
        /**
         *
         * @type {string}
         */
        this.canonical="";
        /**
         * Microdata
         * @type {string}
         */
        this.jsonld="";

    }

    get cleanSlug(){
        return this.slug;
    }
    set cleanSlug(value){
        if(!value){
            if(this.recordPage){
                this.slug=this.recordPage.slug;
            }
        }else{
            this.slug=window.$db.utils.string.slugify(value);
        }
    }

    /**
     *
     * @return {null|PageModel}
     */
    get recordPage(){
        if(this.pageuid){
            return window.$db.getPageByUid(this.pageuid);
        }
        return null;
    }

    /**
     * Renvoie le slug avec ou sans id à la fin
     * modèle = abc/def/id-ghi (donc id tiret au début du dernier slash)
     * @return {string}
     */
    get slugid() {
        if(this.ishome){
            return "";
        }
        if (this.hideid) {
            return this.slug;
        }
        //return `${this.slug}.p${this.id}`;
        return PageUrl.addIdToUrl(this.slug,this.id);
    }

    /**
     * Ajoute un id à l'url
     * modèle = abc/def/id-ghi (donc id tiret au début du dernier slash)
     * @param {String} url
     * @param {String|Number} id
     * @return {string}
     */
    static addIdToUrl(url,id){
        //return `${url}.p${id}`;
        url="/"+url;
        let reg =/^(.*)?\/([^/]+)$/;
        return window.$utils.string.trim(
            url.replace(reg,"$1/"+id+"-"+"$2"),
            "/"
        );

    }

    /**
     * Essaye de trouver un id dans l'url fournie
     * modèle = abc/def/id-ghi (donc id tiret au début du dernier slash)
     * @param $urlWithId
     * @return {string|null}
     */
    static extractIdFromUrl($urlWithId){
        $urlWithId="/"+$urlWithId;
        let reg=/^.*?\/([0-9]+)-[^/]+$/;
        let res=reg.exec($urlWithId)
        if(res){
            return res[1];
        }
        return null;
    }

    /**
     * Seo errors / warnings
     * @return {String[]}
     */
    get errors(){
        if(!this.recordPage){
            return [];
        }
        let c=this.recordPage.meta.getCache("pageUrlErrors")
        if(c){
            return c;
        }
        let errs=[];
        if(!this.title){
            errs.push("Titre manquant !");
        }else if(this.title.length>65){
            errs.push("Le titre est trop long ("+this.title.length+"/50)");
        }
        if(!this.description){
            errs.push("Description manquante");
        }else if(this.description.length>170){
            errs.push("La description est trop longue ("+this.description.length+"/170)");
        }
        if(this.priority<=0){
            errs.push("La page n'est pas indexée");
        }
        //return [];
        // eslint-disable-next-line no-unreachable
        let imageWithoutAlt=this.seoImagesWithoutAlt;
        let imageWithoutTitle=this.seoImagesWithoutTitle;
        if(imageWithoutAlt.length>0){
            errs.push(`${imageWithoutAlt.length} image${imageWithoutAlt.length>1?'s':''} sans ALT`)
        }
        if(imageWithoutTitle.length>0){
            errs.push(`${imageWithoutTitle.length} image${imageWithoutTitle.length>1?'s':''} sans TITLE`)
        }
        this.recordPage.meta.setCache("pageUrlErrors",errs)
        return errs;
    }

    /**
     *
     * @return {ImageField[]}
     */
    get seoImagesWithoutAlt(){
        let r=[];
        for(let im of this.recordPage.imagesFromContent){
            if(!im.alt){
                r.push(im)
            }
        }
        return r;
    }
    /**
     *
     * @return {ImageField[]}
     */
    get seoImagesWithoutTitle(){
        let r=[];
        for(let im of this.recordPage.imagesFromContent){
            if(!im.title){
                r.push(im)
            }
        }
        return r;
    }





}

PageUrl.CHANGE_FREQ_NEVER="never";
PageUrl.CHANGE_FREQ_YEARLY="yearly";
PageUrl.CHANGE_FREQ_MONTHLY="monthly";
PageUrl.CHANGE_FREQ_WEEKLY="weekly";
PageUrl.CHANGE_FREQ_DAILY="daily";
PageUrl.CHANGE_FREQ_HOURLY="hourly";

PageUrl.CHANGE_FREQ_ALL=[
    PageUrl.CHANGE_FREQ_NEVER,
    PageUrl.CHANGE_FREQ_YEARLY,
    PageUrl.CHANGE_FREQ_MONTHLY,
    PageUrl.CHANGE_FREQ_WEEKLY,
    PageUrl.CHANGE_FREQ_DAILY,
    PageUrl.CHANGE_FREQ_HOURLY
]