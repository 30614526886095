import {gsap} from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

/**
 * Un simple écouteur de scroll
 */
export default class NativeScroll{
    constructor() {

        this.y=0;
        this._oldY=0;

        window.addEventListener(
            "scroll",
            this.onScroll.bind(this),
            { passive: true }
        );
    }

    onScroll(){
        this._oldY=this.y;
        this.y=window.scrollY;
    }

    scrollTo(y,duration=0.5,cb=function(){}){
        gsap.to(
            window, {
                duration: duration,
                scrollTo: {y: y, x: 0},
                overwrite: true,
                onComplete:cb
            });
    }

    /**
     * Disfférence entre le précédent et l'actuel scroll
     * @return {number}
     */
    get direction(){
        return this.y-this._oldY;
    }

    /**
     * va t on vers le haut ?
     * @return {boolean}
     */
    get isUp(){
        return this.direction<0;
    }

    /**
     * va t on vers le bas ?
     * @return {boolean}
     */
    get isDown(){
        return this.direction>0 && this.isScrolled;
    }

    /**
     * la page est elle scrollée ?
     * @return {boolean}
     */
    get isScrolled(){
        return this.y>10;
    }
}