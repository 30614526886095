import PageModel from "@/Models/PageModel";
import RecordsField from "@/ee09/fields/RecordsField";

export default class PagelistModel extends PageModel{
    constructor() {
        super();
        /**
         *
         * @type {string}
         */
        this.type="pagelist";


        /**
         * Les pages dans la liste
         * @type {RecordsField}
         */
        this.pages=new RecordsField();

    }

    get errorsMeta(){
        let r=super.errorsMeta;
        return r;
    }
}