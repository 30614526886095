import RecordField from "@/ee09/fields/RecordField";
import EE09Item from "./EE09Item";
import FileField from "@/ee09/fields/FileField";

export default class LinkItem extends EE09Item{
    /**
     * Un objet générique qui sert à gérer des liens qu'ils soient des pages du site ou des liens externes
     * @param {null|PageModel} page Une page du site
     * @param {string} href Un lien en dur, si une page est définie, elle prendra le dessus
     * @param {string} label Un label qui prend le dessus sur celui de la page
     */
    constructor(page=null,href=null,label=null) {
        super();
        /**
         * Type d' item
         * @type {string}
         */
        this.itemType="LinkItem";
        /**
         * Texte à afficher
         * @type {string}
         */
        this._label="";
        if(label){
            this.label=label;
        }
        /**
         * Si true c'est une url externe
         * @type {boolean}
         */
        this.external=false;
        /**
         * Si true c'est un download de fichier
         * @type {boolean}
         */
        this.isDownload=false;
        this.fileField=new FileField();
        /**
         * Si le lien est de type external alors ce champ à un sens ;)
         * @type {string}
         */
        this.url="";
        if(href){
            this.external=true;
            this.url=href
        }
        /**
         * Le lien principal
         * @type {RecordField}
         */
        this.pageRecord=new RecordField();
        if(page){
            this.external=false;
            this.pageRecord.record=page;
        }
        /**
         * Les liens enfants (pour gérer des arborescences)
         * @type {LinkItem[]}
         */
        this.children=[];




    }

    get linkType(){
        if(this.external){
            return "external";
        }
        if(this.isDownload){
            return "download";
        }
        return "page"
    }
    set linkType(value){
        switch (true){
            case value==="page":
                this.isDownload=false;
                this.external=false;
                break;
            case value==="external":
                this.isDownload=false;
                this.external=true;
                break;
            case value==="download":
                this.isDownload=true;
                this.external=false;
                break;
        }
    }

    /**
     * Est ok ou pas ?
     * @return {boolean}
     */
    get isOk(){
        return this.errors.length===0;
    }

    get errors(){
        let r=[];
        if(!this.external && !this.pageRecord.record && !this.isDownload){
            return "Page manquante !";
        }
        if(this.external && !this.url){
            return  "Lien manquant !"
        }
        if(this.isDownload && !this.fileField.isOk){
            return "Fichier manquant"
        }
        return r;
    }


    get label() {
        if(this._label){
            return this._label;
        }
        if(this.isDownload && this.fileField.isOk){
            return "télécharger";
        }
        if(!this.external && this.pageRecord.record){
            return  this.pageRecord.record.name;
        }
        if(this.external && this.url){
            return this.url;
        }
        return "Lien ici";
    }

    set label(value) {
        this._label = value;
    }
    set href(value){
        //
    }
    /**
     * Utilisé si il s'agit d'un lien externe
     * @return {null|string}
     */
    get href() {
        switch (this.linkType){
            case "download":
                if(this.fileField.isOk){
                    return window.$api.dwd(this.fileField.record);
                }
                return "#";

            case "external":
                return this.url;

            default:
                return "";
        }
    }
    /**
     * Utilisé par vue router
     * @return {null|string}
     */
    get to(){
        if(this.external || this.isDownload){
            return "";
        }
        if(this.pageRecord && this.pageRecord.record){
            return this.pageRecord.record.href
        }
        return null;
    }

    get toOrHref(){
        let r=this.href+this.to;
        return r;
    }

    /**
     * blank si le lien est externe
     * @return {string|null}
     */
    get target(){
        return this.external || this.isDownload ?'_blank':null;
    }

    /**
     * Renvoie le composant à utiliser a ou router-link
     * @return {string}
     */
    get component(){
        if(!this.isOk){
            return 'span'
        }
        return this.external?'a':'router-link';
    }

    get hello(){
        return "hello from LinkItem "+Date.now();
    }
}