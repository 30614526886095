/**
 * Une liste d'enregistrements dans la base de données
 */
export default class RecordsField{

    constructor() {
        /**
         * Identifiants unique des DbRecord(s)
         * @type {string[]}
         */
        this.uids=[];
        this._makeItUnique();

        let sortBy="";

        this.sortByName=function(){
            sortBy="name";
        }
        /**
         * Trie les records par name
         */
        this.getSortBy=function(){
            return sortBy;
        }

    }


    /**
     * Dédoublonne les uids
     * @private
     */
    _makeItUnique(){
        this.uids=[...new Set(this.uids)]
    }
    /**
     * Renvoie les records relatifs
     * @return {DbRecord[]}
     */
    get records(){
        let recs=window.$db.getByUids(this.uids);
        if(this.getSortBy()==="name"){
            return window.$db.utils.records.sortByName(recs);
        }
        return recs;
    }

    /**
     * Définit les records associés
     * @param {DbRecord[]} value
     */
    set records(value){
        if(value.length>0){
            this.uids=window.$db.utils.records.getUidsArray(value);
        }else{
            this.uids=[];
        }
        this._makeItUnique();
    }

    /**
     * Ajoute un record à la fin
     * @param {DbRecord} record
     */
    addRecord(record){
        if(record && record.uid){
            this.uids.push(record.uid);
        }
        this._makeItUnique();
    }
    /**
     * Ajoute un record au début
     * @param {DbRecord} record
     */
    prepend(record){
        if(record && record.uid){
            this.uids.unshift(record.uid);
        }
        this._makeItUnique();
    }
    /**
     * Remonte le record d'un niveau dans la liste
     * @param {DbRecord} record
     */
    up(record){
        this.records=window.$db.utils.records.moveUp(this.records,record)
    }
    /**
     * Descend le record d'un niveau dans la liste
     * @param {DbRecord} record
     */
    down(record){
        this.records=window.$db.utils.records.moveDown(this.records,record)
    }

    /**
     * Supprime le record de la liste
     * @param {DbRecord} record
     */
    remove(record){
        this.records=window.$db.utils.records.remove(this.records,record)
    }

    /**
     * Permet de savoir si le record donné est dans la liste
     * @param {DbRecord} record
     * @return {boolean}
     */
    hasRecord(record){
        return this.records.find(r=>r.uid===record.uid) !== undefined;
    }

    hello(){
        alert("hello je suis un recordsField")
    }


}