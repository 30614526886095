<template>
<div class="prev-next" v-if="prev && next">
  <router-link
    :to="prev.href"
    class="prev text-fat"
    @mouseleave.native="hout()"
    @mouseover.native="hoverPrev()"
    :class="{
            'is-long-title':prev.isLongName
          }"
  >
    <span>
      <span>
       <!-- {{ $db.utils.string.noAccents(prev.name) }}-->
        {{prev.name}}
      </span>
      <span class="m">
        <!-- {{ $db.utils.string.noAccents(prev.name) }}-->
        {{prev.name}}
      </span>
    </span>
  </router-link>

  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       x="0px" y="0px" viewBox="0 0 142 70"
       xml:space="preserve">
    <!--line fill="none"
          stroke="var(--color-project)"
          x1="70" y1="70" x2="70" y2="0"
    /-->
    <line ref="horiz-line"
          fill="none"
          stroke="var(--color-project)"
          x1="0" y1="35" x2="140" y2="35"
    />
    <polyline fill="none" stroke="var(--color-project)" points="115,60 140,35 115,10 	"/>
    <polyline fill="none" stroke="var(--color-project)" points="25,10 0,35 25,60 	"/>
  </svg>

  <router-link
    :to="next.href"
    class="next text-fat"
    :class="{
            'is-long-title':next.isLongName
          }"
    @mouseleave.native="hout()"
    @mouseover.native="hoverNext()"
  >
    <span>
      <span>
        <!-- {{ $db.utils.string.noAccents(next.name) }}-->
        {{next.name}}
      </span>
      <span class="m">
        <!-- {{ $db.utils.string.noAccents(next.name) }}-->
        {{next.name}}
      </span>
    </span>
  </router-link>

</div>
</template>

<script>
import PageModel from "@/Models/PageModel";
import {gsap} from "gsap";
export default {
  name: "prev-next",
  props:{
    prev:{
      type:PageModel
    },
    next:{
      type:PageModel
    }
  },
  mounted() {
    this.hout();
  },
  methods:{
    hoverNext(){
      gsap.to(this.$refs["horiz-line"],{duration:0.4,attr:{x1:70+10,x2:140},ease:'Power4.easeOut',overwrite: true});
    //  gsap.to(this.$refs["horiz-line"],{duration:0.4,attr:{x2:140},ease:'Power4.easeOut',overwrite: true});
    },
    hoverPrev(){
      gsap.to(this.$refs["horiz-line"],{duration:0.4,attr:{x1:0,x2:70-10},ease:'Power4.easeOut',overwrite: true});
     // gsap.to(this.$refs["horiz-line"],{duration:0.4,attr:{x2:70-10},ease:'Power4.easeOut',overwrite: true});
    },
    hout(){
      gsap.to(this.$refs["horiz-line"],{duration:0.4,attr:{x1:70,x2:70},ease:'Power4.easeOut',overwrite: true});
    }
  }
}
</script>

<style lang="less">
.prev-next{
  display: flex;
  flex-direction: column;
  @media(min-width: @screen-md){
    flex-direction: row;
  }
  justify-content: flex-start;
  align-items: flex-end;

  position: relative;
  --svg-width:100px;
  --svg-height: 50px;
  --padd:10px;

  //height: var(--svg-height);

  @media(min-width: @screen-md){
    display: flex;
    --svg-width:142px;
    --svg-height: var(--s1);
    --padd:60px;
  }
  >svg{
    margin-left: auto;
    margin-right: auto;
  }

  >a{
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 1em;
    @media(min-width: @screen-md){
      margin-bottom: 0px;
      display: flex;
      align-items: center;
      width: 40%;
    }


    font-style: italic;
    @media(min-width: @screen-md){
      transform: skewX(-10deg) translateX(0.1em);
      font-style: normal;
    }

    color: var(--color-project) !important;
    font-size: var(--s1) !important;
    &.is-long-title{
      font-size: var(--s2) !important;
    }
    font-family: var(--font-title);
    text-transform: uppercase;
    font-weight: 800;
    //width: 30vw;
    //height: 100%;

    text-decoration: none;

    >span{
      position: relative;
      @media(min-width: @screen-md){
        >span{
          &:first-child{
            color: transparent;
            -webkit-text-stroke-color: var(--color-project);
            -webkit-text-stroke-width:1px;
          }
          &:nth-child(2){
            position: absolute;
            top: 0;
            left: 0;

          }
        }
      }
    }
    .m{
      display: none;
    }
    @media(min-width: @screen-md){
      .m{
        display: block;
      }
    }
    .m{
      transition: clip-path 0.2s;
      clip-path: polygon(0 0, 0% 0, 0% 100%, 0% 100%);
    }
    &.prev{
      .m{
        clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
      }
    }
    &:hover{
      .m{
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      }
    }

    &.prev{
      justify-content: flex-start;
    }
    &.next{
      justify-content: flex-end;
      @media(min-width: @screen-md){
        text-align: right;
      }
      &:before{
        content: "Suivant";
      }
    }

    &:before{
      color: #fff;
      font-size: 9px;
      content: "Précedent";
      display: block;
      margin-bottom: 3px;
    }
    @media(min-width: @screen-md){
      &:before{
        display: none;
      }
    }


  }
  svg{
    display: none;
    @media(min-width: @screen-md) {
      display: block;
    }
    width: var(--svg-width);
    height: var(--svg-height);
    pointer-events: none;
    line{
      stroke-width: 1;
      shape-rendering: crispEdges;
    }
    color: var(--color-project);
  }



}

</style>