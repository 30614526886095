const math = require('lodash/math')
export default class EE09MathUtils{
    round(n,precision){
        //return n;
        return math.round(n,precision)
    }
    /**
     * Renvoie une valeur en fonction d'un autre espace de valeur
     * @param {Number} input
     * @param {Number} maxInput
     * @param {Number} maxOutput
     * @param {Number} minInput
     * @param {Number} minOutput
     * @return {Number}
     */
    ratio (input,minInput, maxInput,minOutput, maxOutput) {
        let factor = (input - minInput) / (maxInput - minInput);
        let out = ((maxOutput - minOutput) * factor) + minOutput;
        return out;
    }
}