/**
 * Une référence à un enregistrement dans la base de données
 */
export default class RecordField{

    constructor() {
        /**
         * Identifiant unique du DbRecord
         * @type {string}
         */
        this.uid="";
    }

    /**
     * Renvoie le record relatif
     * @return {DbRecord|null}
     */
    get record(){
        return window.$db.getByUid(this.uid);
    }

    /**
     * Définit le record associé
     * @param {DbRecord|null} value
     */
    set record(value){
        if(value){
            this.uid=value.uid;
        }else{
            this.uid="";
        }
    }

    hello(){
        alert("hello je suis un recordField")
    }

}