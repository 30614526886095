import BlockType from "@/ee09/settings/BlockType";


export default class BlocksSettings{
    constructor() {
        this.blocksModelsTypes=[];
        /**
         * Liste par défaut des blocks que l' on peut insérer
         * @type {*|String[]}
         */
        this.defaultList=[];
    }
    /**
     * Ajoute un type de Block à la BDD
     * @param {string} type identifiant du block
     * @param {string} label Nom pour les humains de ce block
     * @param {string} icon Icône du type de block
     * @param {Function} create Fonction pour en créer un nouveau
     * @return {DbModelType}
     */
    addBlockType(type,label,icon,create){
        let existing=this.getBlockModelType(type);
        if(!existing){
            existing=new BlockType(type,label,icon,create);
            this.blocksModelsTypes.push(existing);
        }else{
            console.warn("addBlockType existe déjà !",existing)
        }
        if(!this.defaultList.find(item=>typeof item === "string" && item===type )){
            this.defaultList.push(type);
        }
        return existing;
    }
    /**
     * Renvoie un BlockType à partir de son identifiant textuel
     * @param {string} type
     * @return {BlockType}
     */
    getBlockModelType(type){
        return this.blocksModelsTypes.find(item=>item.type===type);
    }
}