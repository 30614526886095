const isEqual=require('lodash/isEqual');
// eslint-disable-next-line no-unused-vars
const diff = require("deep-object-diff").diff;
export default class RecordMeta{

    /**
     *
     * @param {DbRecord} record
     */
    constructor(record) {
        this.record=record;
        this.uid=record.uid;
        this._modified=false;
        this.json="";
        this.modifiedTimeout=null;
        /**
         * Le cache permet d'enregistrer des valeurs pour le record sans que cela n'influe sur le record de base
         * @type {{}}
         */
        this.cache={};
    }

    /**
     * renvoie le cache d'une variable donnée
     * @param prop
     * @return {*}
     */
    getCache(prop){
        return this.cache[prop];
    }
    /**
     * Met en cache une variable donnée
     * @param {string} prop
     * @param {*} value
     * @return {*}
     */
    setCache(prop,value){
        this.cache[prop]=value;
    }

    /**
     * Nettoie le cache de toutes les variables
     */
    clearCache(){
        this.cache={};
    }

    /**
     * Renvoie le poids du json
     * @return {number}
     */
    get dataSize(){
        return this.json.length;
    }

    get modified() {
        return this._modified;
    }
    set modified(value) {
        //console.log("modified",value,this.record.name)
        let me=this;
        if(this.modifiedTimeout){
            clearTimeout(this.modifiedTimeout);
        }
        this.modifiedTimeout=setTimeout(function(){
            if(value && me.json){
                let o1=JSON.parse(me.json);
                let o2= JSON.parse(JSON.stringify(me.record));
                if( !isEqual(o1,o2)
                ){
                    console.log("modified "+me.record.name+" "+me.record.uid,diff(o1,o2))
                    console.log("diff",diff(o1,o2))
                    me._modified=true;
                    me.clearCache();
                }else{
                    me._modified=false;
                }
            }else{
                me._modified = value;
            }
        },1000);


    }

}