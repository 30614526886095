<template>
  <div>
    <v-badge
        style="user-select: none"
        :title="errs.join('\n')"
        inline
        :content="errs.length"
        :color="errs.length?'error':'success'"
        :icon="errs.length?'':$icons.check"
    />
    <v-btn icon v-if="page" title="ouvrir dans un nouvel onglet">
      <v-icon
          @click.stop="$layout.openNewTab(page.hrefAbsolute)">
        {{$icons.blank}}
      </v-icon>
    </v-btn>
    <v-btn icon v-if="page" title="aller sur la page">
      <v-icon
          size="24"
          @click.stop="$router.push(page.href);$dbUi.visible=false;">
        {{$icons.arrowTopRight }}
      </v-icon>
    </v-btn>

  </div>
</template>

<script>
import DbRecord from "@/ee09/records/DbRecord";

export default {
  name: "table-fields-errors-actions",
  props:{
    errors:{
      type:Array,
    },
    record:{
      type:DbRecord,
      validator:function(val){
        return val.uid && val.type;
      }
    }
  },
  computed:{
    /**
     * @return {PageModel|null}
     */
    page(){
      if(this.record.isPage){
        return this.record;
      }
      return null;
    },
    errs(){
      if(this.errors){
        return this.errors;
      }
      return this.record.errors;
    }
  }
}
</script>

<style scoped>

</style>