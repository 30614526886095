var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-floating-buttons"},[_c('v-speed-dial',{attrs:{"bottom":"","right":"","direction":"left","fixed":"","open-on-hover":"","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"overlap":"","offset-y":"20","icon":_vm.badgeIcon,"color":_vm.badgeColor,"content":_vm.badgeContent},model:{value:(_vm.pageIsEditable),callback:function ($$v) {_vm.pageIsEditable=$$v},expression:"pageIsEditable"}},[_c('template',{slot:"default"},[(_vm.pageIsEditable)?_c('v-btn',_vm._g(_vm._b({attrs:{"loading":_vm.loading,"color":"pink","dark":"","fab":""},on:{"click":function($event){_vm.$dbUi.singleRecord=_vm.$layout.currentPage}},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},'v-btn',attrs,false),on),[(_vm.fab)?_c('v-icon',[_vm._v(_vm._s(_vm.$icons.edit))]):_c('v-icon',[_vm._v(_vm._s(_vm.$layout.currentPage.modelType.icon))])],1):_c('v-btn',{attrs:{"color":"pink","dark":"","fab":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.edit))])],1)],1)],2)]}}])},[_c('span',[_vm._v("Éditer la page en cours")])])]},proxy:true}]),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(!_vm.$db.userAdmin.canEditContent && _vm.$db.userAdmin.canManageForms)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){return _vm.$router.push('/formulaires')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.at))])],1)]}}],null,false,2745057663)},[_c('span',[_vm._v("Gérer les formulaires")])]):_vm._e(),(_vm.$db.userAdmin.canEditContent)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){return _vm.$dbUi.displayRecords(_vm.$layout.currentPage.type)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.list))])],1)]}}],null,false,2477186355)},[_c('span',[_vm._v("Gérer les données")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){return _vm.$api.logout()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.logout))])],1)]}}])},[_c('span',[_vm._v("Déconnexion")])]),(_vm.$db.userAdmin.isdev)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){return _vm.$dbUi.displaySettings()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.config))])],1)]}}],null,false,731598940)},[_c('span',[_vm._v("Paramètres & préférences")])]):_vm._e(),(_vm.$db.userAdmin.canEditContent)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":_vm.$dbUi.preferences.wysiwygEnabled?'success':'grey'},on:{"click":function($event){_vm.$dbUi.preferences.wysiwygEnabled=!_vm.$dbUi.preferences.wysiwygEnabled}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.magic))])],1)]}}],null,false,702478373)},[_c('span',[_vm._v(_vm._s(_vm.$dbUi.preferences.wysiwygEnabled?'Désactiver':'Activer')+" le mode édition")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }