import BlockItem from "@/ee09/fields/BlockItem";
import LinkItem from "@/ee09/models/LinkItem";

export default class IconesLiensBlock extends BlockItem{
    constructor() {
        super();
        this.blockType="icones-liens-block";
        this.imdb="";
        this.unifrance="";
        this.allocine="";
        this.youtube="";
        this.vimeo="";
    }

}