<template>
  <v-list two-line subheader>

    <v-subheader>Configuration</v-subheader>
    <v-list-item>
      <v-list-item-title>...</v-list-item-title>
    </v-list-item>

    <v-subheader>Préférences</v-subheader>

    <v-list-item two-line @click="$dbUi.preferences.wysiwygEnabled=!$dbUi.preferences.wysiwygEnabled">
      <v-list-item-action>
        <v-checkbox color="success" @click.stop="" v-model="$dbUi.preferences.wysiwygEnabled"/>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Mode édition</v-list-item-title>
        <v-list-item-subtitle>
          {{$dbUi.preferences.wysiwygEnabled?'Désactiver':'Activer'}} l'édition de contenu WYSIWYG
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
        @click.stop="$dbUi.preferences.autoSaveEnabled=!$dbUi.preferences.autoSaveEnabled">
      <v-list-item-action>
        <v-checkbox color="success" @click.stop="" v-model="$dbUi.preferences.autoSaveEnabled"></v-checkbox>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Enregistrement automatique</v-list-item-title>
        <v-list-item-subtitle>Enregistrer automatiquement vos modifications</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item @click="$dbUi.preferences.debugMode=!$dbUi.preferences.debugMode">
      <v-list-item-action>
        <v-checkbox color="success" @click.stop="" v-model="$dbUi.preferences.debugMode"/>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Mode débug</v-list-item-title>
        <v-list-item-subtitle>
          {{$dbUi.preferences.debugMode?'Ne pas afficher':'Afficher'}} les données qui n'intéressent en général que les développeurs.
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-subheader v-if="$dbUi.preferences.debugMode">Développeurs</v-subheader>

    <v-list-item-group v-if="$dbUi.preferences.debugMode">
      <!--graphic tools-->
      <v-list-item
          @click.stop="$dbUi.preferences.displayGraphicsTools=!$dbUi.preferences.displayGraphicsTools">
        <v-list-item-action>
          <v-checkbox color="success" @click.stop="" v-model="$dbUi.preferences.displayGraphicsTools"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Outils de conception graphique</v-list-item-title>
          <v-list-item-subtitle>Affiche le module pixel perfect</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>


    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          Version du programme
        </v-list-item-title>
        <v-list-item-subtitle>
          {{$dbUi.mainAppPackage.version}}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>





  </v-list>
</template>

<script>

export default {
name: "json-db-settings",
  data(){
    return{

    }
  }
}
</script>
