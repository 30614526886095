import PageModel from "@/Models/PageModel";
import RecordsField from "@/ee09/fields/RecordsField";
import ImageField from "@/ee09/fields/ImageField";

export default class PagefilmModel extends PageModel{
    constructor() {
        super();
        /**
         *
         * @type {string}
         */
        this.type="pagefilm";

        /**
         * La liste des acteurs
         * @type {string}
         */
        this.casting=""
        /**
         * L'auteur
         * @type {string}
         */
        this.unfilmde=""
        /**
         * L'affiche du fil
         * @type {ImageField}
         */
        this.affiche=new ImageField();

    }



}