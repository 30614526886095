import DbUiPreferences from "@/ee09/db-ui/DbUiPreferences";
require("./ee-09-admin.less");
export default class DbUi{

    constructor() {
        this.visible=false;
        this.newRecord=null;
        this.mainAppPackage={
            name:'???',
            version:'???',
        }

        this.currentScreen="";
        this.currentRecordsTypes="";

        //composants VUE de base

        //déclare quelques utilitaires en global
        /**
         *
         * @type {DbUiPreferences}
         */
        this.preferences=new DbUiPreferences();

        /**
         * @type {PageModel}
         */
        this.pageHtmlToGenerate=null;
        /**
         * Permet de définir qu'un block precis doit être edité quand il est monté
         * @type {null|BlockItem}
         */
        this.forceEditBlock=null

        /**
         * Le record affiché en single
         * @type {DbRecord|null}
         */
        this._singleRecord=null;
        this.singleRecordOpen=false;

    }

    get singleRecord() {
        return this._singleRecord;
    }

    set singleRecord(value) {
        if(value){
            this.singleRecordOpen=true
        }else{
            this.singleRecordOpen=false
        }
        this._singleRecord = value;
    }

    /**
     * Enregistre le contenu html de toutes les pages
     * @param pages
     */
    saveAllHtmlPages(pages=null){
        let me=this;
        if(pages && pages.length===0){
            console.log("finished");
            return;
        }
        if(!pages){
            pages=window.$db.recordListPage
        }
        let p=pages.shift();
        console.log("save html",p.name);
        p.saveHtml(function(){
            me.saveAllHtmlPages(pages);
        })

    }

    /**
     * Affiche la fenêtre de config
     */
    displaySettings(){
        this.visible=true;
        this.currentScreen="settings";
    }
    /**
     * Affiche la fenêtre SEO
     */
    displaySeo(){
        this.visible=true;
        this.currentScreen="seo-dashboard";
    }

    /**
     * Affiche la liste des records d'un certain type
     * @param {string} type Le type des records à afficher
     */
    displayRecords(type){
        this.visible=true;
        this.currentScreen="records";
        this.currentRecordsTypes=type;
    }
    /**
     * Affiche le formulaire d'édition du record
     * @param {DbRecord} record Le record à afficher
     */
    displayRecord(record){
        //this.visible=true;
        //this.currentScreen="single-record";
        //this.currentRecordsTypes=record.type;
        this._singleRecord=record;
        if(record){
            this.singleRecordOpen=true;
        }
        console.log("displayRecord",record)
    }
    /**
     * Affiche le formulaire de création d'un nouveau record du type spécifié
     * @param {string} type Le type du record
     */
    displayNewRecord(type){
        //this.visible=true;
        //this.currentScreen="single-record";
        //this.currentRecordsTypes=type;
        this.singleRecord=window.$db.settings.getModelType(type).create();
    }
    /**
     * Affiche une fenêtre de confirmation avant d'effacer réellement un record
     * @param {DbRecord} record Le record à effacer
     */
    trashAskRecord(record){
        if(confirm('Êtes vous certain de vouloir effacer '+ record.name + '?')){
            window.$db.trash(record);
        }
    }
}