
import isEmail from "validator/lib/isEmail";

export default class EE09validator{
    /**
     * Renvoie true si c'est un email
     * @param {string} mail
     * @return {boolean}
     */
    isEmail(mail){
        if(!mail){
            return false;
        }
        return isEmail(mail);
    }
}