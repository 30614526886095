import DbRecord from "./DbRecord";
import ImageField from "@/ee09/fields/ImageField";
export default class FileRecord extends DbRecord{
    constructor() {
        super();
        this.type="filerecord";
        /**
         * Clé de hashage
         * @type {string}
         */
        this.md5="";
        /**
         * Chemin du fichier
         * @type {string}
         */
        this.path="";
        /**
         *
         * @type {string}
         */
        this.href="";
        /**
         * Nous dit si le fichier existe bien
         * @type {null|boolean}
         */
        this.isOk=null;
        /**
         * Mime type du fichier
         * @type {string}
         */
        this.mime=""
        /**
         * Poids du fichiers en bytes
         * @type {number}
         */
        this.bytesize=0;
        /**
         * Largeur si il s'agit d'une image
         * @readonly
         * @type {number}
         */
        this.width=0;
        /**
         * Hauteur si il s'agit d'une image
         * @readonly
         * @type {number}
         */
        this.height=0;

        /**
         * Si il s'agit d'une image
         * @type {ImageField}
         */
        this.imagedata=new ImageField(true);
        if(this.uid){
            this.imagedata.uid=this.uid;
        }
    }

    /**
     * Renvoie l'url d'une image redimensionnée
     * @param {Number} width
     * @param {Number} height
     * @param {'contain'|'cover'} mode
     * @param {'contain'|string} backgroundColor
     * @param {Number} quality 0 to 100
     * @param {'jpg'|'png'|'webp'|null} extension
     * @return {string|null}
     */
    resize(width,height,mode="contain",backgroundColor="transparent",quality=80,extension=null){
        if(this.isImage && this.isOk){
            if(this.extension==="svg"){
                return this.href;
            }
            if(!mode){
                mode="contain";
            }
            if(!backgroundColor){
                backgroundColor="808080";
            }
            if(!extension){
                extension=this.extension;
            }
            if(extension==="jpeg"){
                extension="jpg";
            }

            let base=this.href.replace("fs/up","im/fs/up");
            return `${base}/im-${width}x${height}-${mode}-${backgroundColor}-${quality}.${extension}`;
        }
        return null;
    }

    /**
     * Renvoie l'extension (sans point)
     * @return {string}
     */
    get extension(){
        return window.$db.utils.file.ext(this.href);
    }

    /**
     * Renvoie true si le fichier est une image
     * @return {RegExpMatchArray}
     */
    get isImage(){
        return this.mime.match(/image/);
    }

    /**
     * Renvoie true si le fichier est une vidéo
     * @return {RegExpMatchArray}
     */
    get isVideo(){
        return this.mime.match(/video/);
    }

    /**
     * Poids et mime du fichier
     * @return {string}
     */
    get adminSubtitle(){
        let r="";
        if(!this.isOk){
            return "Fichier manquant !";
        }
        r+= window.$db.utils.file.humanSize(this.bytesize)+" "+this.mime;
        if(this.isImage){
            r+=` ${this.width}x${this.height}px`
        }
        return r;
    }

    /**
     * Icone qui correspond au type de fichier
     * @return {string}
     */
    get adminSubtitleIcon(){
        return window.$db.utils.file.mdiIcon(this.path,this.mime);
    }

    /**
     * Une thumbnail visible uniquement dans l'admin
     * @return {null|string}
     */
    get adminThumb(){

        if(this.isImage){
            //TODO WEB gérer version alternative
            return this.resize(50,50,"contain","transparent",80)
        }
        return null;
    }

    get errors(){
        let r=super.errors;
        if(!this.isOk){
            r.push('Le fichier est manquant')
        }else{
            if(this.isImage){
                r=r.concat(this.imagedata.errors);
            }
        }
        return r;
    }

    /**
     * texte normalisé (sans acccents en minuscules)
     * @return {string}
     */
    get keywordsSearch(){
        return super.keywordsSearch+" "+window.$utils.string.normalizeString(this.path);
    }

}