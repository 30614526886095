import EE09localStorage from "../utils/EE09localStorage";

/**
 * Les préférence utilisateur pour les outils d'administration
 */
export default class DbUiPreferences extends EE09localStorage{

    constructor() {
        super("DbUiPreferences");
        //pour que les propriétés soient réactives, il faut les déclarer !
        this._debugMode=false;
        this._displayGraphicsTools=false;
        this._wysiwygEnabled=false;
        this._autoSaveEnabled=false;
        /**
         * Tab actif dans les éditions de record
         * @private
         */
        this._editRecordActiveTab=false;
    }

    get debugMode() {
        return window.$db.userAdminDev && this.getValue("debugMode");
    }
    set debugMode(value) {
        this.setValue("debugMode",value);
    }

    get displayGraphicsTools() {
       return window.$db.userAdminDev && this.getValue("displayGraphicsTools") && this.debugMode;
    }
    set displayGraphicsTools(value) {
       this.setValue("displayGraphicsTools",value);
    }

    get wysiwygEnabled() {
       return window.$db.userAdmin
           && window.$db.userAdmin.canEditContent
           && this.getValue("wysiwygEnabled");
    }
    set wysiwygEnabled(value) {
       this.setValue("wysiwygEnabled",value);
    }

    get autoSaveEnabled() {
       return window.$db.userAdmin
           && this.getValue("autoSaveEnabled")!== false;
    }
    set autoSaveEnabled(value) {
       this.setValue("autoSaveEnabled",value);
    }

    get editRecordActiveTab() {
        let r= this.getValue("editRecordActiveTab");
        if(!r){
            this.editRecordActiveTab="meta";
        }
        return r;
    }
    set editRecordActiveTab(value) {
        this.setValue("editRecordActiveTab",value);
    }

}