import IframeBlock from "@/Models/blocks/media/IframeBlock";
import ItemList from "@/ee09/models/ItemList";
import ImageItem from "@/ee09/models/ImageItem";
import ImageField from "@/ee09/fields/ImageField";

export default class MediaTextBlock extends IframeBlock{
    constructor() {
        super();
        this.blockType="media-text-block";

        /**
         * Type de média joué à gauche
         * @type {"iframe"|"photos"}
         */
        this.mediaType="iframe";

        /**
         *
         * @type {ImageItemList}
         */
        this.photos=new ItemList(
            function(imageField){
                return new ImageItem(imageField);
            }
        );
        /**
         *
         * @type {ImageItemList}
         */
        this.logos=new ItemList(
            function(imageField){
                return new ImageItem(imageField);
            }
        );

    }

    mount(data) {
        super.mount(data);
        this.logos.links=true;
        this.photos._processData();
        this.logos._processData();
        for(let i of this.photos.items){
            if(!i.image){
                i.image=new ImageField();
            }
        }
        for(let i of this.logos.items){
            if(!i.image){
                i.image=new ImageField();
            }
        }
    }
}