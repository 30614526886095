<template>
<div class="admin-floating-buttons">

  <!--bouton en bas -->
  <v-speed-dial
      v-model="fab"
      bottom
      right
      direction="left"
      fixed
      open-on-hover
      transition="slide-y-reverse-transition"
  >
    <template v-slot:activator>
      <!-- Bouton principal ouvre la page en cours -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-badge overlap
                   v-model="pageIsEditable"
                   offset-y="20"
                   :icon="badgeIcon"
                   :color="badgeColor"
                   :content="badgeContent">
            <template slot="default">
              <v-btn
                  v-if="pageIsEditable"
                  :loading = "loading"
                  v-bind="attrs" v-on="on"
                  v-model="fab"
                  color="pink" dark fab
                  @click="$dbUi.singleRecord=$layout.currentPage">
                <v-icon v-if="fab">{{ $icons.edit }}</v-icon>
                <v-icon v-else>{{$layout.currentPage.modelType.icon}}</v-icon>
              </v-btn>
              <v-btn v-else color="pink" dark fab>
                <v-icon>{{ $icons.edit }}</v-icon>
              </v-btn>
            </template>
          </v-badge>

        </template>
        <span>Éditer la page en cours</span>
      </v-tooltip>
    </template>

    <!-- sous menu -->

    <!-- formulaires (pour ceux qui peuvent rien faire d'autre) -->
    <v-tooltip top
        v-if="!$db.userAdmin.canEditContent && $db.userAdmin.canManageForms">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-bind="attrs" v-on="on"
            fab dark small
            @click="$router.push('/formulaires')">
          <v-icon>{{$icons.at}}</v-icon>
        </v-btn>
      </template>
      <span>Gérer les formulaires</span>
    </v-tooltip>

    <!-- browser records -->
    <v-tooltip top
        v-if="$db.userAdmin.canEditContent">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-bind="attrs" v-on="on"
            fab dark small
            @click="$dbUi.displayRecords($layout.currentPage.type)">
            <v-icon>{{$icons.list}}</v-icon>
        </v-btn>
      </template>
      <span>Gérer les données</span>
    </v-tooltip>

    <!-- Déconnexion -->
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-bind="attrs" v-on="on"
            fab dark small
            @click="$api.logout()">
          <v-icon>{{$icons.logout}}</v-icon>
        </v-btn>
      </template>
      <span>Déconnexion</span>
    </v-tooltip>

    <!-- paramètres -->
    <v-tooltip top
        v-if="$db.userAdmin.isdev">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-bind="attrs" v-on="on"
            fab dark small
            @click="$dbUi.displaySettings()">
            <v-icon>{{$icons.config}}</v-icon>
        </v-btn>
      </template>
      <span>Paramètres & préférences</span>
    </v-tooltip>

    <!-- mode édition (ou pas) -->

    <v-tooltip top v-if="$db.userAdmin.canEditContent">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-bind="attrs" v-on="on"
            fab dark small
            :color="$dbUi.preferences.wysiwygEnabled?'success':'grey'"
            @click="$dbUi.preferences.wysiwygEnabled=!$dbUi.preferences.wysiwygEnabled">
            <v-icon>{{$icons.magic}}</v-icon>
        </v-btn>
      </template>
      <span>{{$dbUi.preferences.wysiwygEnabled?'Désactiver':'Activer'}} le mode édition</span>
    </v-tooltip>



  </v-speed-dial>



</div>
</template>

<script>
export default {
  name: "admin-floating-buttons",
  components: {},
  data(){
    return{
      fab:false,
      show:false
    }
  },
  computed:{
    pageIsEditable(){
      return this.$db.userAdmin.canEditContent && this.$layout.currentPage.uid;
    },
    badgeIcon(){
      if(this.$layout.currentPage.uid){
        return this.$layout.currentPage.errors.length?'':this.$icons.check
      }
      return "";
    },
    badgeColor(){
      if(this.$layout.currentPage.uid) {
        return this.$layout.currentPage.errors.length ? 'error' : 'success'
      }
      return "";
    },
    badgeContent(){
      if(this.$layout.currentPage.uid) {
        return this.$layout.currentPage.errors.length?this.$layout.currentPage.errors.length:''
      }
      return "";
    },
    loading(){
      if(this.$layout.currentPage.uid) {
        return this.$layout.currentPage.meta.modified
      }
      return false;
    }
  }
}
</script>
