<template>
  <div class="admin-dialog-nav">

    <!--top bar-->
    <div class="top">
      <v-toolbar color="gray">
        <v-toolbar-title>Administration</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="align-center">
          <v-btn text @click="$dbUi.visible=false">
          <v-icon >{{$icons.close}}</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
    </div>

    <!--menu-->
    <div class="left elevation-5">
      <div class="wrap">



      <!--liste records-->
        <div v-if="$db.userAdmin.canEditContent">
          <v-subheader>Bibliothèque</v-subheader>
          <record-model-type-list/>

          <v-divider></v-divider>
        </div>






      <v-subheader>Général</v-subheader>
      <v-list dense color="transparent">


        <v-list-item
            v-if="$db.user.canManageForms"
            @click="$router.push('/formulaires');$dbUi.visible=false;">
          <v-list-item-content>
            <v-list-item-title>
              Gestion des formulaires
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-avatar>
            <v-icon size="24">{{$icons.at}}</v-icon>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item
            v-if="$db.user.canEditLayout"
            @click="$dbUi.singleRecord=$db.getByName('opendata','Menu principal')">
          <v-list-item-content>
            <v-list-item-title>
              Menu principal
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-avatar>
            <v-icon size="24">{{$icons.pageHeader}}</v-icon>
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item
            v-if="$db.user.canEditLayout"
            @click="$dbUi.singleRecord=$db.getByName('opendata','footer')">
          <v-list-item-content>
            <v-list-item-title>
              Footer
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-avatar>
            <v-icon  size="24">{{$icons.pageFooter}}</v-icon>
          </v-list-item-avatar>
        </v-list-item>


        <v-list-item
            v-if="$db.userAdmin.canEditContent"
            @click="$dbUi.displaySeo()" :v-model="true">
          <v-list-item-content>
            <v-list-item-title>
              Gestionnaire SEO
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-avatar>
            <v-icon size="24">{{$icons.link}}</v-icon>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item
            v-if="$db.user.canEditRedirections"
            @click="$router.push('/redirections');$dbUi.visible=false;">
          <v-list-item-content>
            <v-list-item-title>
              Redirections
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-avatar>
            <v-icon size="24">{{$icons.redirection}}</v-icon>
          </v-list-item-avatar>
        </v-list-item>



        <v-list-item
            v-if="$db.userAdmin.isdev"
            @click="$dbUi.saveAllHtmlPages()">
          <v-list-item-content>
            <v-list-item-title>
              Actualiser le cache HTML
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-avatar>
            <v-icon size="24">{{ $icons.cached }}</v-icon>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item
            @click="$router.push('/style-guide');$dbUi.visible=false;">
          <v-list-item-content>
            <v-list-item-title>
              Style guide
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-avatar>
            <v-icon size="24">{{$icons.formatPaint}}</v-icon>
          </v-list-item-avatar>
        </v-list-item>

      </v-list>


        <v-spacer/>

        <!--paramètres-->
        <div class="bottom"
             v-if="$db.userAdmin.isdev">
          <v-divider></v-divider>
          <v-list dense color="transparent">
            <v-list-item @click="$dbUi.displaySettings()" :v-model="true">
              <v-list-item-content>
                <v-list-item-title>
                  Paramètres
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar>
                <v-icon size="24">{{$icons.config}}</v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import RecordModelTypeList from "../records/record-type/record-model-type-list";
export default {
name: "admin-dialog-nav",
  components: {RecordModelTypeList}
}
</script>
