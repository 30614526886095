<template>
<div class="top-logo">
  <router-link
      class="logo"
      :to="$layout.specialPages.home.href"
  >
    <img :src="`${$layout.publicPath}logo-${$layout.site}-v3.svg`"
         width="407" height="184"
         title="Picseyes logo"
         alt="Picseyes logo"
    >
  </router-link>
</div>
</template>

<script>
export default {
  name: "top-logo"
}
</script>

<style lang="less">
.top-logo{

  display: flex;
  .logo{
    text-align: left;
    flex-grow: 0;
    img{
      height: 100%;
      display: block;
      width: auto;
    }
  }
}
</style>