import BlockItem from "@/ee09/fields/BlockItem";
import LinkItem from "@/ee09/models/LinkItem";

export default class BtnBlock extends BlockItem{
    constructor() {
        super();
        this.blockType="btn-block";
        this.lien=new LinkItem();
    }

}