/**
 * Désigne un type de block
 */
import BlockItem from "@/ee09/fields/BlockItem";

export default class BlockType{
    /**
     *
     * @param {String} type identifiant du type de block
     * @param {String} label Nom du type de block affiché
     * @param {String} icon Icône de preview du type de block
     * @param {Function} create La fonction pour créer un nouveau block de ce type
     */
    constructor(type,label,icon,create) {
        /**
         * Le type de block tel qu' enregistré dans la bases de données
         * @type {String}
         */
        this.type=type;
        /**
         * icone de représentation
         * @type {String}
         */
        this.icon=icon;
        /**
         * nom de représentation
         * @type {String}
         */
        this.label=label;
        /**
         * nom de la représentation au pluriel
         * @type {string}
         */
        this.labelPlural=this.label+"s";
        /**
         * Méthode permettant de créer un nouveau block de ce type
         * Il FAUT écraser cette méthode pour chaque type afin qu'elle renvoie une instance appropriée
         * @return {BlockItem}
         */
        this.create=function(){
            return new BlockItem();
        }
        if(create){
            this.create=create;
        }
        /**
         * Le nom du composant pour éditer ce type de block, ce composant par défaut DOIT se nommer "[type-de-block]-edit.vue"
         * Si null c'est qu'il n'y a pas de composant d'édition
         * @type {string|null}
         */
        this.editComponent=this.type+"-edit";
        /**
         * Le nom du composant pour l'affichage, ce composant par défaut DOIT se nommer comme le "type-de-block].vue"
         * @type {String}
         */
        this.displayComponent=this.type;

    }

    /**
     * Renvoie un nouveau BlockItem
     * @return {BlockItem}
     */
    getInstance(jsonData){
        let r= this.create();
        if(jsonData){
            r.mount(jsonData);
        }
        return r;
    }
}