import ParagraphBlock from "@/Models/blocks/texte/ParagraphBlock";
import IframeBlock from "@/Models/blocks/media/IframeBlock";
import CoreStringBlock from "@/Models/core-blocks/CoreStringBlock";
import CoreStringHtmlBlock from "@/Models/core-blocks/CoreStringHtmlBlock";
import CoreLinkItemBlock from "@/Models/core-blocks/CoreLinkItemBlock";
import CoreLinkItemListBlock from "@/Models/core-blocks/CoreLinkItemListBlock";
import Vue from "vue";
import SpacingBlock from "@/Models/blocks/texte/SpacingBlock";
import BtnBlock from "@/Models/blocks/links/BtnBlock";
import AccordionBlock from "@/Models/blocks/media/AccordionBlock";
import AccordionLocationBlock from "@/Models/blocks/media/AccordionLocationBlock";
import MediaTextBlock from "@/Models/blocks/media/MediaTextBlock";
import IconesLiensBlock from "@/Models/blocks/links/IconesLiensBlock";
import DiaporamaBlock from "@/Models/blocks/media/DiaporamaBlock";
import FilmsBlock from     "@/Models/blocks/links/FilmsBlock";
import LocationsBlock from "@/Models/blocks/links/LocationsBlock";

//-----------------core blocks---------------------

let $db=window.$db;

$db.blocksSettings.addBlockType(
    "core-string-block",
    "Texte",
    window.$icons.formatText,
    function(){
        return new CoreStringBlock();
    }
)
$db.blocksSettings.addBlockType(
    "core-string-html-block",
    "Texte enrichi",
    window.$icons.formatText,
    function(){
        return new CoreStringHtmlBlock();
    }
)
$db.blocksSettings.addBlockType(
    "core-link-item-block",
    "Lien",
    window.$icons.formatText,
    function(){
        return new CoreLinkItemBlock();
    }
)
$db.blocksSettings.addBlockType(
    "core-link-item-list-block",
    "Liste de liens",
    window.$icons.formatBullet,
    function(){
        return new CoreLinkItemListBlock();
    }
)

//-----------------project blocks-------------------------

$db.blocksSettings.defaultList=[];
$db.blocksSettings.defaultList.push({"label":"Blocs textes"})

$db.blocksSettings.addBlockType(
    "paragraph-block",
    "Paragraphe",
    window.$icons.text,
    function(){
        return new ParagraphBlock();
    }
)
$db.blocksSettings.addBlockType(
    "spacing-block",
    "Espacement",
    window.$icons.pageBreak,
    function(){
        return new SpacingBlock();
    }
)
$db.blocksSettings.getBlockModelType("spacing-block").editComponent=null;
Vue.component('spacing-block',() =>import("./blocks/texte/spacing-block"));

//---medias----
$db.blocksSettings.defaultList.push({"label":"Médias"});

//iframe
$db.blocksSettings.addBlockType(
    "iframe-block",
    "Iframe (Youtube, Vimeo, etc..)",
    window.$icons.fileCodeHtml,
    function(){
        return new IframeBlock();
    }
)
//diaporama
$db.blocksSettings.addBlockType(
    "diaporama-block",
    "Diaporama",
    window.$icons.fileImage,
    function(){
        return new DiaporamaBlock();
    }
)
//media-text
$db.blocksSettings.addBlockType(
    "media-text-block",
    "Media + texte",
    window.$icons.imgText,
    function(){
        return new MediaTextBlock();
    }
)

//accordion
$db.blocksSettings.addBlockType(
    "accordion-block",
    "Accordéon (média + texte)",
    window.$icons.pageBody,
    function(){
        return new AccordionBlock();
    }
)

//btn
$db.blocksSettings.addBlockType(
    "btn-block",
    "Bouton",
    window.$icons.fileCodeHtml,
    function(){
        return new BtnBlock();
    }
)


if(window.$layout.isFilms){
//---liens----

    //liste de films
    $db.blocksSettings.defaultList.push({"label":"Liens"});

    //liens imdb etc...
    $db.blocksSettings.addBlockType(
        "icones-liens-block",
        "Fiche tech IMDB, Allocine, etc...",
        window.$icons.link,
        function(){
            return new IconesLiensBlock();
        }
    )


    $db.blocksSettings.addBlockType(
        "films-block",
        "Liste de films",
        window.$icons.fileMovie,
        function(){
            return new FilmsBlock();
        }
    );
}else{
    //prod only

    //rental
    $db.blocksSettings.defaultList.push({"label":"Rental"});

    $db.blocksSettings.addBlockType(
        "locations-block",
        "Liste de locations",
        window.$icons.euro,
        function(){
            return new LocationsBlock();
        }
    );


    $db.blocksSettings.addBlockType(
        "accordion-location-block",
        "Produit en location",
        window.$icons.euro,
        function(){
            return new AccordionLocationBlock();
        }
    );
    $db.blocksSettings.defaultList=$db.blocksSettings.defaultList.filter(type=>{
        return type !== "accordion-location-block"
    })
}

