import DbRecord from "@/ee09/records/DbRecord";
import BlocksField from "@/ee09/fields/BlocksField";

export default class OpendataModel extends DbRecord{
    constructor() {
        super();
        this.type="opendata";
        /**
         * Les datas...qui sont en fait des blocks nommés
         * @type {BlocksField}
         */
        this.data=new BlocksField([
            "core-string-block",
            "core-string-html-block",
            "core-link-item-block"
        ]);

    }

    /**
     * Renvoie (et crée au besoin) un block d'un type donné
     * @param fieldName
     * @param blockType
     * @return {BlockItem}
     */
    getNamedBlock(fieldName,blockType){
        let b=this.data.getByName(fieldName,blockType);
        return b;
    }
    /**
     * Renvoie (et crée au besoin) un champ texte
     * @param {string} fieldName
     * @return {string}
     */
    getString(fieldName){
        /**
         * @type {CoreStringBlock}
         */
        let b=this.getNamedBlock(fieldName,"core-string-block");
        return b.text;
    }
    /**
     * Renvoie (et crée au besoin) un champ texte HTML
     * @param {string} fieldName
     * @return {string}
     */
    getStringHtml(fieldName){
        /**
         * @type {CoreStringHtmlBlock}
         */
        let b=this.getNamedBlock(fieldName,"core-string-html-block");
        return b.text;
    }
    /**
     * Renvoie (et crée au besoin) un LinkItem
     * @param {string} fieldName
     * @return {LinkItem}
     */
    getLinkItem(fieldName){
        /**
         * @type {CoreLinkItemBlock}
         */
        let b=this.getNamedBlock(fieldName,"core-link-item-block");
        return b.link;
    }

    /**
     * Renvoie (et crée au besoin) une liste de LinkItem
     * @param {string} fieldName
     * @return {LinkItemList}
     */
    getLinkItemList(fieldName){
        /**
         * @type {CoreLinkItemListBlock}
         */
        let b=this.getNamedBlock(fieldName,"core-link-item-list-block");
        return b.list;
    }





}