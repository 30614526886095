import BlockItem from "@/ee09/fields/BlockItem";

export default class CoreStringBlock extends BlockItem{
    constructor() {
        super();
        this.blockType="core-string-block";
        /**
         * Le texte
         * @type {string}
         */
        this.text="";
    }

}