const seedrandom = require('seedrandom');
export default class EE09numberUtils{
    /**
     * Renvoie un nombre pseudo aléatoire
     * @param {String} seed
     * @return {Number}
     */
    randomSeed(seed){
        return seedrandom(seed);
    }
}