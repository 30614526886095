<template>
  <v-list-item-avatar
      tile
      color="transparent"
      class="record-list-item-avatar-icon">
    <record-icon  :record="record"/>
  </v-list-item-avatar>
</template>

<script>
import RecordIcon from "../record-icon"
import RecordMixin from "../RecordMixin";
export default {
  name: "record-list-item-avatar-icon",
  components:{RecordIcon},
  mixins:[RecordMixin]
}
</script>